<!-- src/components/TreeView.vue -->
<template>
    <ul class="tree">
      <li v-for="node in nodes" :key="node.id">
        <div
          :class="['tree-node', { expanded: isExpanded(node), 'is-file': node.type === 'items' }]"
          @click="handleNodeClick(node)"
        >
          <span v-if="node.type === 'folders'">
            {{ isExpanded(node) ? '-' : '+' }}
          </span>
          {{ node.attributes.name ||  node.attributes.displayName }}
        </div>
  
        <!-- Render children if the node is expanded -->
        <TreeView
          v-if="node.children && isExpanded(node)"
          :nodes="node.children"
          @file-action="onFileAction"
          :project-id="projectId"
        />
      </li>
    </ul>
  </template>
  
  <script setup>
  import { ref, reactive, defineProps, defineEmits } from "vue";
  import { getProjectContents } from "@/services/autodesk";
  import TreeView from "./treeNode.vue";
  // Props for reusability
  const props = defineProps({
    nodes: { type: Array, required: true },
    projectId: { type: String, required: false, default: "b.49d5cc0e-0976-42d2-aeec-dcbea141e296" },
  });
  
  // Emit to handle file actions
  const emit = defineEmits(["file-action", "file-action-parent"]);
  
  // State to track expanded folders
  const expandedNodes = reactive({});
  
  // Toggle folder expansion and fetch children if needed
  const toggleFolder = async (node) => {
    if (node.type === "folders") {
      // Toggle expansion state
      expandedNodes[node.id] = !expandedNodes[node.id];
  
      // Fetch children only if expanded and not already fetched
      if (expandedNodes[node.id] && !node.children) {
        try {
          const children = await getProjectContents( props.projectId, node.id);
          node.children = children.data;
        } catch (err) {
          console.error("Error fetching folder contents:", err);
        }
      }
    }
  };
  
  // Handle node click: expand folder or trigger file action
  const handleNodeClick = (node) => {
    if (node.type === "folders") {
      toggleFolder(node);
    } else if (node.type === "items") {
      emit("file-action", node);
    }
  };
  
  // Check if a node is expanded
  const isExpanded = (node) => expandedNodes[node.id];


  const onFileAction = (node) => {
  emit('file-action', node); // Emit the file-action event with the clicked node
};
  </script>
  
  <style scoped>
  .tree {
    list-style: none;
    padding-left: 1em;
  }
  
  .tree-node {
    cursor: pointer;
    margin: 5px 0;
    font-size: 15px;
  }
  
  .tree-node.is-file {
    color: cornflowerblue;
    font-weight: bold;
  }
  
  .tree-node.expanded {
    font-weight: bold;
  }
  </style>
  