import {createRouter, createWebHistory} from "vue-router"
import Body from '../components/body';
import Default from '../pages/dashboard/defaultPage.vue';
import Login from '../pages/auth/login.vue';
import ProjectListing from '../pages/dashboard/project/projectListing.vue';
import CreateProject from '../pages/dashboard/project/createProject.vue';
import Settings from '../pages/dashboard/project/settings.vue';
import LoadDocument from '../pages/dashboard/documents/index.vue';
import BreakDown from '../pages/dashboard/breakdown/index.vue';
import Database from '../pages/dashboard/database/index.vue';
import Takeoff from '../pages/dashboard/takeoff/index.vue';
import Filters from '../pages/dashboard/filters/index.vue';

const routes =[
    {
        path: '/',
        component: Body,
    
        children: [
          {
            path:'/document/load',
            name:'LoadDocument',
            component:LoadDocument,
          },
    
        ]
      },
      {
        path: '/',
        component: Body,
    
        children: [
          {
            path: '/project/listing',
            name: 'projectListing',
            component: ProjectListing,
          },
          {
            path: '/project/create',
            name: 'createProject',
            component: CreateProject,
          },
          {
            path: '/project/settings',
            name: 'settings',
            component: Settings,
          },
    
        ]
      },
      {
        path: '/',
        component: Body,
    
        children: [
          {
            path:'/breakdown',
            name:'BreakDown',
            component:BreakDown,
          },
          {
            path:'/database',
            name:'DataBase',
            component:Database,
          },
          {
            path:'/takeoff',
            name:'Takeoff',
            component:Takeoff,
          },
          {
            path:'/filters',
            name:'Filters',
            component:Filters,
          },
    
        ]
      },
      {
        path:'/auth/login',
        name:'Login',
        component:Login,
        meta: {
            title: 'Login',
          }
      },
]
const router=createRouter({
    history: createWebHistory(),
    routes
})
export default router