<template>
    <div class="create-project">
        <div v-if="isLoading" class="overlay-loader"></div>
        <div class="bg-generic mb-3">
            <div class="row">
                <div class="col-md-2">
                    <div class="lookup-list h-100">
                        <div @click="selectLookup('demo')">
                            <p :class="selectedLookup === 'demo' ? 'active-accordion' : ''" data-bs-toggle="collapse"
                                data-bs-target="#demo">DataBase</p>
                            <div id="demo" class="collapse d-none">
                                <p @click="selectChildLookup('category')" class="font-12">- Category</p>
                                <p @click="selectChildLookup('system')" class="font-12">- System</p>
                                <p @click="selectChildLookup('subsystem')" class="font-12">- Sub System</p>
                                <p @click="selectChildLookup('style')" class="font-12">- Style</p>
                            </div>
                        </div>
                        <div :class="selectedLookup === 'owner' ? 'active-accordion mb-3' : 'mb-3'"
                            @click="selectLookup('owner')">
                            <p class="m-0" data-bs-toggle="collapse" data-bs-target="#owner">Owner Type</p>
                        </div>
                        <div :class="selectedLookup === 'pricing' ? 'active-accordion mb-3' : 'mb-3'"
                            @click="selectLookup('pricing')">
                            <p class="m-0" data-bs-toggle="collapse" data-bs-target="#pricing">Pricing Unit</p>
                        </div>
                        <div :class="selectedLookup === 'manufacturer' ? 'active-accordion mb-3' : 'mb-3'"
                            @click="selectLookup('manufacturer')">
                            <p class="m-0" data-bs-toggle="collapse" data-bs-target="#manufacturer">Manufacturer</p>
                        </div>
                        <div :class="selectedLookup === 'costCode' ? 'active-accordion mb-3' : 'mb-3'"
                            @click="selectLookup('costCode')">
                            <p class="m-0" data-bs-toggle="collapse" data-bs-target="#costCode">Cost Code</p>
                        </div>

                        <div :class="selectedLookup === 'createPart' ? 'active-accordion mb-3' : 'mb-3'"
                            @click="selectLookup('createPart')">
                            <p class="m-0" data-bs-toggle="collapse" data-bs-target="#costCode">Create Part</p>
                        </div>

                        <div :class="selectedLookup === 'createElement' ? 'active-accordion mb-3' : 'mb-3'"
                            @click="selectLookup('createElement')">
                            <p class="m-0" data-bs-toggle="collapse" data-bs-target="#costCode">Create Element</p>
                        </div>

                        <div :class="selectedLookup === 'createBundle' ? 'active-accordion mb-3' : 'mb-3'"
                            @click="selectLookup('createBundle')">
                            <p class="m-0" data-bs-toggle="collapse" data-bs-target="#costCode">Create Bundle</p>
                        </div>

                        <div :class="selectedLookup === 'createGroup' ? 'active-accordion mb-3' : 'mb-3'"
                            @click="selectLookup('createGroup')">
                            <p class="m-0" data-bs-toggle="collapse" data-bs-target="#costCode">Create Group</p>
                        </div>

                    </div>

                </div>
                <div class="col-md-10">
                    <!-- <div class="bg-generic mb-3 mt-3 me-4 px-3 py-2 d-flex toolbar-upper">
                        <i @click="goBack" class="icon-arrow-left pe-3 clickable align-self-center"></i>
                        <span class="mini-separator me-3"></span>
                        <i class="icon-save clickable align-self-center pe-2 blue-color"></i>
                        <span class=" align-self-center clickable">Save</span>
                        <span class="mini-separator mx-3"></span>
                        <i class="icon-plus pe-2 clickable align-self-center green-color"></i>
                        <span @click="addNewLookup" class="clickable align-self-center">New</span>
                        <span class="mini-separator mx-3"></span>
                        <i class="icon-trash pe-2 clickable align-self-center red-color"></i>
                        <span class=" align-self-center">Delete</span>
                    </div> -->
                    <div class="lookup-detail-area mt-3">
                        <div v-if="!selectedParentLookup" class="row">
                            <div class="col-md-3">
                                <div class="d-flex x-between mb-2">
                                    <h5 class="">Categories</h5>
                                    <i @click="createNewLookup('category')"
                                        class="icon-plus pe-2 clickable align-self-center green-color" type="button"
                                        data-bs-toggle="modal" data-bs-target="#addLookupModal"></i>
                                </div>

                                <div class="database-tile">


                                    <div class="database-tile-list">
                                        <div v-for="category in categoriesData" @click="handleClickCategory(category.id)"
                                            class="link-color clickable mb-2">- {{ category.name }}</div>
                                    </div>
                                </div>
                            </div>

                            <div v-if="showSystemTile" class="col-md-3">
                                <div class="d-flex x-between mb-2">
                                    <h5 class="">System</h5>
                                    <i v-if="selectedSystemsList.length > 0" @click="createNewLookup('system')"
                                        class="icon-plus pe-2 clickable align-self-center green-color" type="button"
                                        data-bs-toggle="modal" data-bs-target="#addLookupModal"></i>
                                </div>
                                <div class="database-tile">


                                    <div class="database-tile-list">
                                        <div v-if="selectedSystemsList.length > 0" v-for="system in selectedSystemsList"
                                            @click="handleClickSystem(system.id)" class="link-color clickable mb-2">- {{
                                                system.name }}</div>

                                        <div class="center-align" v-else>No data</div>
                                    </div>
                                </div>
                            </div>

                            <div v-if="showSubSystemTile" class="col-md-3">
                                <div class="d-flex x-between mb-2">
                                    <h5 class="">Sub System</h5>
                                    <i v-if="selectedSubSystemsList.length > 0" @click="createNewLookup('subsystem')"
                                        class="icon-plus pe-2 clickable align-self-center green-color" type="button"
                                        data-bs-toggle="modal" data-bs-target="#addLookupModal"></i>
                                </div>
                                <div class="database-tile">


                                    <div class="database-tile-list">
                                        <div v-if="selectedSubSystemsList.length > 0"
                                            v-for="subSystem in selectedSubSystemsList"
                                            @click="handleClickSubsystem(subSystem.id)" class="link-color clickable mb-2">-
                                            {{ subSystem.name }}</div>
                                        <div class="center-align" v-else>No data</div>
                                    </div>
                                </div>
                            </div>

                            <div v-if="showStyleTile" class="col-md-3">
                                <div class="d-flex x-between mb-2">
                                    <h5 class="">Style</h5>
                                    <i v-if="selectedStyleList.length > 0" @click="createNewLookup('style')"
                                        class="icon-plus pe-2 clickable align-self-center green-color" type="button"
                                        data-bs-toggle="modal" data-bs-target="#addLookupModal"></i>
                                </div>
                                <div class="database-tile">

                                    <div class="database-tile-list">
                                        <div v-if="selectedStyleList.length > 0" v-for="style in selectedStyleList"
                                            class="link-color clickable mb-2">- {{ style.name }}</div>
                                        <div class="center-align" v-else>No data</div>
                                    </div>
                                </div>
                            </div>

                            <div v-if="showOwnerTypeTile" class="col-md-3">
                                <div class="d-flex x-between mb-2">
                                    <h5 class="">Owner Type</h5>
                                    <!-- <i @click="addStyle" class="icon-plus pe-2 clickable align-self-center green-color"></i> -->
                                </div>
                                <div class="database-tile">

                                    <div class="database-tile-list">
                                        <div class="link-color clickable mb-2">- Owner 1</div>
                                        <div class="link-color clickable mb-2">- Owner 2</div>
                                    </div>
                                </div>
                            </div>

                            <div v-if="showPricingTile" class="col-md-3">
                                <div class="d-flex x-between mb-2">
                                    <h5 class="">Pricing Unit</h5>
                                    <i @click="addStyle" class="icon-plus pe-2 clickable align-self-center green-color"></i>
                                </div>
                                <div class="database-tile">

                                    <div class="database-tile-list">
                                        <div class="link-color clickable mb-2">- Pricing 1</div>
                                        <div class="link-color clickable mb-2">- Pricing 2</div>
                                    </div>
                                </div>
                            </div>

                            <div v-if="showManufacturerTile" class="col-md-3">
                                <div class="d-flex x-between mb-2">
                                    <h5 class="">Manufacturer</h5>
                                    <i @click="addStyle" class="icon-plus pe-2 clickable align-self-center green-color"></i>
                                </div>
                                <div class="database-tile">

                                    <div class="database-tile-list">
                                        <div class="link-color clickable mb-2">- Manufacturer 1</div>
                                        <div class="link-color clickable mb-2">- Manufacturer 2</div>
                                    </div>
                                </div>
                            </div>

                            <div v-if="showCostCodeTile" class="col-md-3">
                                <div class="d-flex x-between mb-2">
                                    <h5 class="">Cost Code</h5>
                                    <i @click="addStyle" class="icon-plus pe-2 clickable align-self-center green-color"></i>
                                </div>
                                <div class="database-tile">

                                    <div class="database-tile-list">
                                        <div class="link-color clickable mb-2">- Cost code 1</div>
                                        <div class="link-color clickable mb-2">- Cost code 2</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div v-else>
                            <div class="row">
                                <div v-if="selectedLookup === 'owner'" class="col-md-3">
                                    <div class="d-flex x-between mb-2">
                                        <h5 class="">Owner Type</h5>
                                        <i v-if="ownerTypeData.length > 0" @click="createNewLookup('ownerType')"
                                            class="icon-plus pe-2 clickable align-self-center green-color" type="button"
                                            data-bs-toggle="modal" data-bs-target="#addLookupModal"></i>
                                    </div>

                                    <div class="database-tile">


                                        <div class="database-tile-list">
                                            <div v-for="owner in ownerTypeData" @click="handleClickOwner(owner.id)"
                                                class="link-color clickable mb-2">- {{ owner.name }}</div>
                                        </div>
                                    </div>
                                </div>

                                <div v-if="selectedLookup === 'pricing'" class="col-md-3">
                                    <div class="d-flex x-between mb-2">
                                        <h5 class="">Pricing Units</h5>
                                        <i @click="createNewLookup('pricingUnit')"
                                            class="icon-plus pe-2 clickable align-self-center green-color" type="button"
                                            data-bs-toggle="modal" data-bs-target="#addLookupModal"></i>
                                    </div>

                                    <div class="database-tile">


                                        <div class="database-tile-list">
                                            <div v-for="pricing in pricingUnitsData" @click="handleClickPricing(pricing.id)"
                                                class="link-color clickable mb-2">- {{ pricing.name }}</div>
                                        </div>
                                    </div>
                                </div>

                                <div v-if="selectedLookup === 'manufacturer'" class="col-md-3">
                                    <div class="d-flex x-between mb-2">
                                        <h5 class="">Manufacturers</h5>
                                        <i @click="createNewLookup('manufacturer')"
                                            class="icon-plus pe-2 clickable align-self-center green-color" type="button"
                                            data-bs-toggle="modal" data-bs-target="#addLookupModal"></i>
                                    </div>

                                    <div class="database-tile">


                                        <div class="database-tile-list">
                                            <div v-for="manufacturer in manufacturerData"
                                                @click="handleClickManufacturer(manufacturer.id)"
                                                class="link-color clickable mb-2">- {{ manufacturer.name }}</div>
                                        </div>
                                    </div>
                                </div>

                                <div v-if="selectedLookup === 'costCode'" class="col-md-3">
                                    <div class="d-flex x-between mb-2">
                                        <h5 class="">Cost Code</h5>
                                        <i @click="createNewLookup('costCode')"
                                            class="icon-plus pe-2 clickable align-self-center green-color" type="button"
                                            data-bs-toggle="modal" data-bs-target="#addLookupModal"></i>
                                    </div>

                                    <div class="database-tile">


                                        <div class="database-tile-list">
                                            <div v-for="costCode in costCodeData" @click="handleClickCostCode(costCode.id)"
                                                class="link-color clickable mb-2">- {{ costCode.name }}</div>
                                        </div>
                                    </div>
                                </div>

                                <div v-if="selectedLookup === 'createPart'">
                                    <ul class="nav nav-tabs border-tab d-flex-res mb-4" id="top-tab" role="tablist">
                                        <li class="nav-item"><a class="nav-link active" id="part-creation-tab"
                                                data-bs-toggle="tab" href="#top-create" role="tab" aria-controls="top-create"
                                                aria-selected="true">Create</a></li>
                                        <li class="nav-item"><a class="nav-link" id="part-listing-tab" data-bs-toggle="tab"
                                                href="#top-listing" role="tab" aria-controls="top-listing"
                                                aria-selected="false">Listing</a></li>
                                                <li class="nav-item"><a class="nav-link" id="part-pricing-tab" data-bs-toggle="tab"
                                                href="#top-part-pricing" role="tab" aria-controls="top-part-pricing"
                                                aria-selected="false">Pricing & Cost </a></li>
                                    </ul>

                                    <div class="tab-content ps-3" id="top-tabContent">
                                        <div class="tab-pane fade show active" id="top-create" role="tabpanel" aria-labelledby="part-creation-tab">
                                            <div class="row">
                                        <div class="col-md-3 form-group mb-2">
                                            <label class="col-form-label">Name</label>
                                            <div class="form-input">
                                                <input v-model="newPartName" class="form-control" />
                                            </div>
                                        </div>

                                        <div class="col-md-3 form-group mb-2">
                                            <label class="col-form-label">Upc</label>
                                            <div class="form-input">
                                                <input v-model="newPartUpc" class="form-control" />
                                            </div>
                                        </div>

                                        <div class="col-md-3 form-group mb-2">
                                            <label class="col-form-label">Cut Sheet Url</label>
                                            <div class="form-input">
                                                <input v-model="newPartCutSheetUrl" class="form-control" />
                                            </div>
                                        </div>

                                        <div class="col-md-3 form-group mb-2">
                                            <label class="col-form-label">Manufacturer Part Number</label>
                                            <div class="form-input">
                                                <select v-model="newPartManufacturerPartNumber" class="form-select">
                                                    <option :value="manufacturer.id"
                                                        v-for="manufacturer in manufacturerData">{{ manufacturer.name }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>

                                        <div class="col-md-3 form-group mb-2">
                                            <label class="col-form-label">Version Number</label>
                                            <div class="form-input">
                                                <input v-model="newPartVersionNumber" class="form-control" />
                                            </div>
                                        </div>

                                        <div class="col-md-3 form-group mb-2">
                                            <label class="col-form-label">Owner Type</label>
                                            <select v-model="newPartOwnerType" class="form-select">
                                                <option :value="owner.id" v-for="owner in ownerTypeData">{{ owner.name }}
                                                </option>
                                            </select>
                                        </div>

                                        <div class="col-md-3 form-group mb-2">
                                            <label class="col-form-label">Cost Code</label>
                                            <select v-model="newPartCostCode" class="form-select">
                                                <option :value="costCode.id" v-for="costCode in costCodeData">{{
                                                    costCode.name }}</option>
                                            </select>
                                        </div>

                                        <div class="col-md-3 form-group mb-2">
                                            <label class="col-form-label">Category</label>
                                            <select @change="handleClickCategory(newPartCategory)" v-model="newPartCategory"
                                                class="form-select">
                                                <option :value="category.id" v-for="category in categoriesData">{{
                                                    category.name }}</option>
                                            </select>
                                        </div>

                                        <div class="col-md-3 form-group mb-2">
                                            <label class="col-form-label">System</label>
                                            <select @change="handleClickSystem(newPartSystem)" v-model="newPartSystem"
                                                class="form-select">
                                                <option :value="system.id" v-for="system in selectedSystemsList">{{
                                                    system.name }}</option>
                                            </select>
                                        </div>

                                        <div class="col-md-3 form-group mb-2">
                                            <label class="col-form-label">Sub System</label>
                                            <select @change="handleClickSubsystem(newPartSubSystem)"
                                                v-model="newPartSubSystem" class="form-select">
                                                <option :value="subSystem.id" v-for="subSystem in selectedSubSystemsList">{{
                                                    subSystem.name }}</option>
                                            </select>
                                        </div>

                                        <div class="col-md-3 form-group mb-2">
                                            <label class="col-form-label">Style</label>
                                            <select v-model="newPartStyle" class="form-select">
                                                <option :value="style.id" v-for="style in selectedStyleList">{{ style.name
                                                }}</option>
                                            </select>
                                        </div>

                                    </div>

                                    <div class="row">
                                        <div class="col-md-3 form-group mb-2 new-part-image">
                                            <label for="file-upload" class="custom-file-upload btn btn-primary">
                                                Click To Upload Images
                                            </label>
                                            <input id="file-upload" @change="onFileChange" type="file" multiple="multiple">
                                            <div v-if="newPartImages.length > 0">
                                                {{ newPartImages.length }} images uploaded.
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-md-3 form-group mb-2 new-part-image">
                                            <label for="file-upload-RFA" class="custom-file-upload btn btn-primary">
                                                Click To Upload RFA
                                            </label>
                                            <input id="file-upload-RFA" @change="onFileChangeRFA" type="file" multiple="multiple">
                                            <div v-if="newPartRFA.length > 0">
                                                {{ newPartRFA.length }} file uploaded.
                                            </div>
                                        </div>
                                    </div>
                                    <button @click="handleCreatePart" class="btn btn-primary mt-3 float-right">Create
                                    Part</button>
                                        </div>
                                        <div class="tab-pane fade" id="top-listing" role="tabpanel" aria-labelledby="part-listing-tab">
                                            <ag-grid-vue :defaultColDef="defaultColDef" :rowSelection="'multiple'" :pagination="true" :paginationPageSize="10"
                                                :paginationPageSizeSelector="[10, 20, 30]" :rowData="allPartsData" :columnDefs="colDefsPart"
                                                style="height: 600px; width: 100%;" class="ag-theme-quartz">
                                            </ag-grid-vue>
                                        </div>

                                        <div class="tab-pane fade" id="top-part-pricing" role="tabpanel" aria-labelledby="part-pricing-tab">
                                            <div class="mb-5">
                                                <h4>Update Labor Cost</h4>
                                                
                                                <div class="row mt-3">
                                                    <div class="col-md-3 form-group mb-2">
                                            <label class="col-form-label">Select Part</label>
                                            <select v-model="updateLaborCostPart" class="form-select">
                                                <option :value="part.id" v-for="part in allPartsData">{{ part.name }}
                                                </option>
                                            </select>
                                        </div>

                                        <div class="col-md-3 form-group mb-2">
                                            <label class="col-form-label">Bid Labor</label>
                                            <input v-model="updateLaborCostBidLabor" class="form-control" />
                                        </div>


                                        <div class="col-md-3 form-group mb-2">
                                            <label class="col-form-label">Bid Labor Unit</label>
                                            <input v-model="updateLaborCostBidLaborUnitID" class="form-control" />
                                        </div>

                                        <div class="col-md-3 form-group mb-2">
                                            <label class="col-form-label">Co Labor 1</label>
                                            <input v-model="updateLaborCostCoLabor1" class="form-control" />
                                        </div>

                                        <div class="col-md-3 form-group mb-2">
                                            <label class="col-form-label">Co Labor 1 Unit</label>
                                            <input v-model="updateLaborCostCoLabor1Unit" class="form-control" />
                                        </div>

                                        <div class="col-md-3 form-group mb-2">
                                            <label class="col-form-label">Co Labor 2</label>
                                            <input v-model="updateLaborCostCoLabor2" class="form-control" />
                                        </div>

                                        <div class="col-md-3 form-group mb-2">
                                            <label class="col-form-label">Co Labor 2 Unit</label>
                                            <input v-model="updateLaborCostCoLabor2Unit" class="form-control" />
                                        </div>

                                        <div class="col-md-3 form-group mb-2">
                                            <label class="col-form-label">Neca 1</label>
                                            <input v-model="updateLaborCostNeca1" class="form-control" />
                                        </div>

                                        <div class="col-md-3 form-group mb-2">
                                            <label class="col-form-label">Neca 1 Unit</label>
                                            <input v-model="updateLaborCostNeca1Unit" class="form-control" />
                                        </div>

                                        <div class="col-md-3 form-group mb-2">
                                            <label class="col-form-label">Neca 2</label>
                                            <input v-model="updateLaborCostNeca2" class="form-control" />
                                        </div>

                                        <div class="col-md-3 form-group mb-2">
                                            <label class="col-form-label">Neca 2 Unit</label>
                                            <input v-model="updateLaborCostNeca2Unit" class="form-control" />
                                        </div>

                                        <div class="col-md-3 form-group mb-2">
                                            <label class="col-form-label">Neca 3</label>
                                            <input v-model="updateLaborCostNeca3" class="form-control" />
                                        </div>

                                        <div class="col-md-3 form-group mb-2">
                                            <label class="col-form-label">Neca 3 Unit</label>
                                            <input v-model="updateLaborCostNeca3Unit" class="form-control" />
                                        </div>

                                        

                                                </div>
                                                <button
                                                @click="handleUpdateLaborCost"
                                                class="btn btn-primary mt-3 float-right">Update</button>
                                            </div>

                                            <div class="mb-5">
                                                <h4>Update Price</h4>

                                                <div class="row mt-3">
                                                    <div class="col-md-3 form-group mb-2">
                                            <label class="col-form-label">Select Part</label>
                                            <select v-model="updateLaborPricePart" class="form-select">
                                                <option :value="part.id" v-for="part in allPartsData">{{ part.name }}
                                                </option>
                                            </select>
                                        </div>

                                        <div class="col-md-3 form-group mb-2">
                                            <label class="col-form-label">Book Price</label>
                                            <input v-model="updateLaborPriceBookPrice" class="form-control" />
                                        </div>


                                        <div class="col-md-3 form-group mb-2">
                                            <label class="col-form-label">Book Price Unit</label>
                                            <input v-model="updateLaborPriceBookPriceUnit" class="form-control" />
                                        </div>

                                        <div class="col-md-3 form-group mb-2">
                                            <label class="col-form-label">Price 1</label>
                                            <input v-model="updateLaborPrice1" class="form-control" />
                                        </div>

                                        <div class="col-md-3 form-group mb-2">
                                            <label class="col-form-label">Price 1 Unit</label>
                                            <input v-model="updateLaborPrice1Unit" class="form-control" />
                                        </div>

                                        <div class="col-md-3 form-group mb-2">
                                            <label class="col-form-label">Price 2</label>
                                            <input v-model="updateLaborPrice2" class="form-control" />
                                        </div>

                                        <div class="col-md-3 form-group mb-2">
                                            <label class="col-form-label">Price 2 Unit</label>
                                            <input v-model="updateLaborPrice2Unit" class="form-control" />
                                        </div>

                                        <div class="col-md-3 form-group mb-2">
                                            <label class="col-form-label">Price 3</label>
                                            <input v-model="updateLaborPrice3" class="form-control" />
                                        </div>

                                        <div class="col-md-3 form-group mb-2">
                                            <label class="col-form-label">Price 3 Unit</label>
                                            <input v-model="updateLaborPrice3Unit" class="form-control" />
                                        </div>

                                                </div>
                                                <button
                                                @click="handleUpdateLaborPrice"
                                                class="btn btn-primary mt-3 float-right">Update</button>
                                            </div>

                                            <div class="mb-5">
                                                <h4>Update Cost And Price By Percentage</h4>
                                                <div class="row mt-3">
                                                    <div class="col-md-3 form-group mb-2">
                                            <label class="col-form-label">Percentage</label>
                                            <input v-model="updateCostPricePercentage" class="form-control" />
                                        </div>
                                        <div class="mt-3">
                <label class="col-form-label d-block pb-7">Update Labor Cost</label>
                <div class="custom-switch">
                  <span @click="handleSwitchUpdateLaborCost(1)" :class="isUpdateLaborCost === 1 ? 'active' : ''">Yes</span>
                  <span @click="handleSwitchUpdateLaborCost(2)" :class="isUpdateLaborCost === 2 ? 'active' : ''">No</span>
                </div>
              </div>

              <div class="mt-3">
                <label class="col-form-label d-block pb-7">Update Price</label>
                <div class="custom-switch">
                  <span @click="handleSwitchUpdateLaborPrice(1)" :class="isUpdateLaborPrice === 1 ? 'active' : ''">Yes</span>
                  <span @click="handleSwitchUpdateLaborPrice(2)" :class="isUpdateLaborPrice === 2 ? 'active' : ''">No</span>
                </div>
              </div>

              <div class="mt-3">
                <label class="col-form-label d-block pb-7">Is Increasing?</label>
                <div class="custom-switch">
                  <span @click="handleSwitchUpdateLaborPriceIncrease(1)" :class="isUpdateLaborPriceIncrease === 1 ? 'active' : ''">Yes</span>
                  <span @click="handleSwitchUpdateLaborPriceIncrease(2)" :class="isUpdateLaborPriceIncrease === 2 ? 'active' : ''">No</span>
                </div>
              </div>
                                                </div>
                                            </div>
                                            <button
                                                @click="handleUpdateCostAndPriceByPercentage"
                                                class="btn btn-primary mt-3 float-right">Update</button>

                                        </div>

                                    </div>

                                </div>

                                <div v-if="selectedLookup === 'createElement'">
                                    <ul class="nav nav-tabs border-tab d-flex-res mb-4" id="top-tab" role="tablist">
                                        <li class="nav-item"><a class="nav-link active" id="element-creation-tab"
                                                data-bs-toggle="tab" href="#top-create-element" role="tab" aria-controls="top-create-element"
                                                aria-selected="true">Create</a></li>
                                        <li class="nav-item"><a class="nav-link" id="element-listing-tab" data-bs-toggle="tab"
                                                href="#top-listing-element" role="tab" aria-controls="top-listing-element"
                                                aria-selected="false">Listing</a></li>
                                    </ul>

                                    <div class="tab-content ps-3" id="top-tabContent">

                                        <div class="tab-pane fade show active" id="top-create-element" role="tabpanel" aria-labelledby="element-creation-tab">
                                    <div class="row">
                                        <div class="col-md-3 form-group mb-2">
                                            <label class="col-form-label">Name</label>
                                            <div class="form-input">
                                                <input v-model="newElementName" class="form-control" />
                                            </div>
                                        </div>

                                        <div class="col-md-3 form-group mb-2">
                                            <label class="col-form-label">Version Number</label>
                                            <div class="form-input">
                                                <input v-model="newElementVersionNumber" class="form-control" />
                                            </div>
                                        </div>


                                        <div class="col-md-3 form-group mb-2">
                                            <label class="col-form-label">Size</label>
                                            <div class="form-input">
                                                <input v-model="newElementSize" class="form-control" />
                                            </div>
                                        </div>

                                        <div class="col-md-3 form-group mb-2">
                                            <label class="col-form-label">Inventory Number</label>
                                            <input v-model="newElementInventoryNumber" class="form-control" />
                                        </div>

                                        <div class="col-md-3 form-group mb-2">
                                            <label class="col-form-label">Part Owner Type</label>
                                            <select v-model="newElementPartOwnerTypeId" class="form-select">
                                                <option :value="owner.id" v-for="owner in ownerTypeData">{{ owner.name }}
                                                </option>
                                            </select>
                                        </div>

                                        <div class="col-md-3 form-group mb-2">
                                            <label class="col-form-label">Object File Location Url</label>
                                            <input v-model="newElementObjectFileLocationUrl" class="form-control" />
                                        </div>

                                    </div>

                                    <div class="row">
                                        <div class="col-md-3 form-group mb-2">
                                            <label class="col-form-label">Description</label>
                                            <div class="form-input">
                                                <textarea v-model="newElementDescription" class="form-control"
                                                    rows="2"></textarea>
                                            </div>
                                        </div>

                                        <div class="col-md-3 form-group mb-2">
                                            <label class="col-form-label">Notes</label>
                                            <div class="form-input">
                                                <textarea v-model="newElementNotes" class="form-control"
                                                    rows="2"></textarea>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-md-3 form-group mb-2">
                                            <label class="col-form-label">Parts</label>
                                            <multiselect :close-on-select="false" :showLabels="false" selectLabel=""
                                                deselectLabel="" :searchable="false" :show-labels="false"
                                                v-model="newElementParts" tag-placeholder="" placeholder="Select"
                                                label="name" track-by="id" :options="allPartsData" :multiple="true"
                                                :taggable="true" @tag="addTag"></multiselect>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-md-3 form-group mb-2 new-part-image">
                                            <label for="file-upload" class="custom-file-upload btn btn-primary">
                                                Click To Upload Images
                                            </label>
                                            <input id="file-upload" @change="onFileChangeElement" type="file"
                                                multiple="multiple">
                                            <div v-if="newElementImages.length > 0">
                                                {{ newElementImages.length }} images uploaded.
                                            </div>
                                        </div>
                                    </div>
                                    <button @click="handleCreateElement" class="btn btn-primary mt-3 float-right">Create
                                        Element</button>
                                        </div>

                                        <div class="tab-pane fade" id="top-listing-element" role="tabpanel" aria-labelledby="element-listing-tab">
                                            <ag-grid-vue :defaultColDef="defaultColDef" :rowSelection="'multiple'" :pagination="true" :paginationPageSize="10"
                                                :paginationPageSizeSelector="[10, 20, 30]" :rowData="allElementsData" :columnDefs="colDefsElement"
                                                style="height: 600px; width: 100%;" class="ag-theme-quartz">
                                            </ag-grid-vue>
                                        </div>
                                    </div>

                                </div>

                                <div v-if="selectedLookup === 'createBundle'">
                                    <ul class="nav nav-tabs border-tab d-flex-res mb-4" id="top-tab" role="tablist">
                                        <li class="nav-item"><a class="nav-link active" id="bundle-creation-tab"
                                                data-bs-toggle="tab" href="#top-create-bundle" role="tab" aria-controls="top-create-bundle"
                                                aria-selected="true">Create</a></li>
                                        <li class="nav-item"><a class="nav-link" id="bundle-listing-tab" data-bs-toggle="tab"
                                                href="#top-listing-bundle" role="tab" aria-controls="top-listing-bundle"
                                                aria-selected="false">Listing</a></li>
                                    </ul>

                                    <div class="tab-content ps-3" id="top-tabContent">
                                        <div class="tab-pane fade show active" id="top-create-bundle" role="tabpanel" aria-labelledby="bundle-creation-tab">
                                            <div class="row">
                                        <div class="col-md-3 form-group mb-2">
                                            <label class="col-form-label">Name</label>
                                            <div class="form-input">
                                                <input v-model="newBundleName" class="form-control" />
                                            </div>
                                        </div>



                                        <div class="col-md-3 form-group mb-2">
                                            <label class="col-form-label">Size</label>
                                            <div class="form-input">
                                                <input v-model="newBundleSize" class="form-control" />
                                            </div>
                                        </div>

                                        <div class="col-md-3 form-group mb-2">
                                            <label class="col-form-label">Inventory Number</label>
                                            <input v-model="newBundleInventoryNumber" class="form-control" />
                                        </div>

                                        <div class="col-md-3 form-group mb-2">
                                            <label class="col-form-label">Part Owner Type</label>
                                            <select v-model="newBundlePartOwnerTypeId" class="form-select">
                                                <option :value="owner.id" v-for="owner in ownerTypeData">{{ owner.name }}
                                                </option>
                                            </select>
                                        </div>

                                        <div class="col-md-3 form-group mb-2">
                                            <label class="col-form-label">Object File Location Url</label>
                                            <input v-model="newBundleObjectFileLocationUrl" class="form-control" />
                                        </div>

                                    </div>

                                    <div class="row">
                                        <div class="col-md-3 form-group mb-2">
                                            <label class="col-form-label">Description</label>
                                            <div class="form-input">
                                                <textarea v-model="newBundleDescription" class="form-control"
                                                    rows="2"></textarea>
                                            </div>
                                        </div>

                                        <div class="col-md-3 form-group mb-2">
                                            <label class="col-form-label">Notes</label>
                                            <div class="form-input">
                                                <textarea v-model="newBundleNotes" class="form-control" rows="2"></textarea>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-md-3 form-group mb-2">
                                            <label class="col-form-label">Elements</label>
                                            <multiselect :close-on-select="false" :showLabels="false" selectLabel=""
                                                deselectLabel="" :searchable="false" :show-labels="false"
                                                v-model="newBundleElements" tag-placeholder="" placeholder="Select"
                                                label="name" track-by="id" :options="allElementsData" :multiple="true"
                                                :taggable="true" @tag="addTag"></multiselect>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-md-3 form-group mb-2 new-part-image">
                                            <label for="file-upload" class="custom-file-upload btn btn-primary">
                                                Click To Upload Images
                                            </label>
                                            <input id="file-upload" @change="onFileChangeBundle" type="file"
                                                multiple="multiple">
                                            <div v-if="newBundleImages.length > 0">
                                                {{ newBundleImages.length }} images uploaded.
                                            </div>
                                        </div>
                                    </div>
                                    <button @click="handleCreateBundle" class="btn btn-primary mt-3 float-right">Create
                                        Bundle</button>
                                        </div>

                                        <div class="tab-pane fade" id="top-listing-bundle" role="tabpanel" aria-labelledby="bundle-listing-tab">
                                            <ag-grid-vue :defaultColDef="defaultColDef" :rowSelection="'multiple'" :pagination="true" :paginationPageSize="10"
                                                :paginationPageSizeSelector="[10, 20, 30]" :rowData="allBundlesData" :columnDefs="colDefsBundle"
                                                style="height: 600px; width: 100%;" class="ag-theme-quartz">
                                            </ag-grid-vue>
                                        </div>

                                        </div>
                                    
                                </div>

                                <div v-if="selectedLookup === 'createGroup'">
                                    <div class="row">
                                        <div class="col-md-3 form-group mb-2">
                                            <label class="col-form-label">Name</label>
                                            <div class="form-input">
                                                <input v-model="newGroupName" class="form-control" />
                                            </div>
                                        </div>



                                        <div class="col-md-3 form-group mb-2">
                                            <label class="col-form-label">Size</label>
                                            <div class="form-input">
                                                <input v-model="newGroupSize" class="form-control" />
                                            </div>
                                        </div>


                                        <div class="col-md-3 form-group mb-2">
                                            <label class="col-form-label">Part Owner Type</label>
                                            <select v-model="newGroupPartOwnerTypeId" class="form-select">
                                                <option :value="owner.id" v-for="owner in ownerTypeData">{{ owner.name }}
                                                </option>
                                            </select>
                                        </div>

                                    </div>

                                    <div class="row">
                                        <div class="col-md-3 form-group mb-2">
                                            <label class="col-form-label">Description</label>
                                            <div class="form-input">
                                                <textarea v-model="newGroupDescription" class="form-control"
                                                    rows="2"></textarea>
                                            </div>
                                        </div>

                                        <div class="col-md-3 form-group mb-2">
                                            <label class="col-form-label">Notes</label>
                                            <div class="form-input">
                                                <textarea v-model="newGroupNotes" class="form-control" rows="2"></textarea>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-md-3 form-group mb-2">
                                            <label class="col-form-label">Bundles</label>
                                            <multiselect :close-on-select="false" :showLabels="false" selectLabel=""
                                                deselectLabel="" :searchable="false" :show-labels="false"
                                                v-model="newGroupBundles" tag-placeholder="" placeholder="Select"
                                                label="name" track-by="id" :options="allBundlesData" :multiple="true"
                                                :taggable="true" @tag="addTag"></multiselect>
                                        </div>
                                    </div>

                                    <button @click="handleCreateGroup" class="btn btn-primary mt-3 float-right">Create
                                        Group</button>
                                </div>

                            </div>
                        </div>

                        <!-- <div v-if="isAddNewLookup" class="row">
                            <div v-if="selectedChildLookup === 'category'" class="row">
                                <div class="col-md-3 form-group">
                                <label class="col-form-label">Category Name</label>
                                <div class="form-input">
                                    <input class="form-control" />
                                </div>
                            </div>
                            </div>

                            <div v-if="selectedChildLookup === 'system'" class="row">
                                <div class="col-md-3 form-group">
                                <label class="col-form-label">System Name</label>
                                <div class="form-input">
                                    <input class="form-control" />
                                </div>
                            </div>

                            <div class="col-md-3 form-group">
                                <label class="col-form-label">Category</label>
                                <div class="form-input">
                                          <select class="form-select">
                                            <option>1</option>
                                            <option>2</option>
                                          </select>
                                        </div>
                            </div>
                            </div>


                            <div v-if="selectedChildLookup === 'subsystem'" class="row">
                                <div class="col-md-3 form-group">
                                <label class="col-form-label">SubSystem Name</label>
                                <div class="form-input">
                                    <input class="form-control" />
                                </div>
                            </div>

                            <div class="col-md-3 form-group">
                                <label class="col-form-label">System</label>
                                <div class="form-input">
                                          <select class="form-select">
                                            <option>1</option>
                                            <option>2</option>
                                          </select>
                                        </div>
                            </div>
                            </div>


                            <div v-if="selectedChildLookup === 'style'" class="row">
                                <div class="col-md-3 form-group">
                                <label class="col-form-label">Style Name</label>
                                <div class="form-input">
                                    <input class="form-control" />
                                </div>
                            </div>

                            <div class="col-md-3 form-group">
                                <label class="col-form-label">Sub System</label>
                                <div class="form-input">
                                          <select class="form-select">
                                            <option>1</option>
                                            <option>2</option>
                                          </select>
                                        </div>
                            </div>
                            </div>

                            <div v-if="selectedChildLookup === 'ownertype'" class="row">
                                <div class="col-md-3 form-group">
                                <label class="col-form-label">Owner Name</label>
                                <div class="form-input">
                                    <input class="form-control" />
                                </div>
                            </div>
                            </div>

                            <div v-if="selectedChildLookup === 'pricing'" class="row">
                                <div class="col-md-3 form-group">
                                <label class="col-form-label">Name</label>
                                <div class="form-input">
                                    <input class="form-control" />
                                </div>
                            </div>

                            <div class="col-md-3 form-group">
                                <label class="col-form-label">Equivalent</label>
                                <div class="form-input">
                                    <input class="form-control" />
                                </div>
                            </div>
                            </div>

                            <div v-if="selectedChildLookup === 'manufacturer'" class="row">
                                <div class="col-md-3 form-group">
                                <label class="col-form-label">Name</label>
                                <div class="form-input">
                                    <input class="form-control" />
                                </div>
                            </div>

                            <div class="col-md-3 form-group">
                                <label class="col-form-label">Web URL</label>
                                <div class="form-input">
                                    <input class="form-control" />
                                </div>
                            </div>
                            <div class="col-md-3 form-group">
                                <label class="col-form-label">Phone</label>
                                <div class="form-input">
                                    <input class="form-control" />
                                </div>
                            </div>

                            <div class="col-md-3 form-group">
                                <label class="col-form-label">Email</label>
                                <div class="form-input">
                                    <input class="form-control" />
                                </div>
                            </div>
                            <div class="col-md-3 form-group">
                                <label class="col-form-label">Address</label>
                                <div class="form-input">
                                    <input class="form-control" />
                                </div>
                            </div>
                            </div>

                            <div v-if="selectedChildLookup === 'costCode'" class="row">
                                <div class="col-md-3 form-group">
                                <label class="col-form-label">Name</label>
                                <div class="form-input">
                                    <input class="form-control" />
                                </div>
                            </div>

                            <div class="col-md-3 form-group">
                                <label class="col-form-label">Abbreviation</label>
                                <div class="form-input">
                                    <input class="form-control" />
                                </div>
                            </div>
                            </div>

                        </div>
                        <div v-else class="row">
                            <div class="col-md-12">
                                <table v-if="selectedChildLookup === 'category'" class="table">
                                    <thead>
                                        <tr>
                                            <th>Id</th>
                                            <th>Name</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>1</td>
                                            <td>Category</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table v-if="selectedChildLookup === 'system'" class="table">
                                    <thead>
                                        <tr>
                                            <th>Id</th>
                                            <th>Name</th>
                                            <th>Category</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>1</td>
                                            <td>System</td>
                                            <td>System Category</td>
                                        </tr>
                                    </tbody>
                                </table>

                                <table v-if="selectedChildLookup === 'subsystem'" class="table">
                                    <thead>
                                        <tr>
                                            <th>Id</th>
                                            <th>Name</th>
                                            <th>System</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>1</td>
                                            <td>Sub System</td>
                                            <td>System</td>
                                        </tr>
                                    </tbody>
                                </table>

                                <table v-if="selectedChildLookup === 'style'" class="table">
                                    <thead>
                                        <tr>
                                            <th>Id</th>
                                            <th>Name</th>
                                            <th>Sub System</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>1</td>
                                            <td>Style</td>
                                            <td>Sub System</td>
                                        </tr>
                                    </tbody>
                                </table>


                            </div>

                        </div> -->
                    </div>

                </div>
            </div>
        </div>

        <div class="modal fade" id="addLookupModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
            aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-md" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Create {{ selectedLookupToCreate }}</h5>
                        <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">

                        <div v-if="selectedLookupToCreate === 'category'" class="row">
                            <div class="col-md-6 form-group">
                                <label class="col-form-label">Category Name</label>
                                <div class="form-input">
                                    <input v-model="newCategoryName" class="form-control" />
                                </div>
                            </div>
                        </div>


                        <div v-if="selectedLookupToCreate === 'system'" class="row">
                            <div class="col-md-6 form-group">
                                <label class="col-form-label">System Name</label>
                                <div class="form-input">
                                    <input v-model="newSystemName" class="form-control" />
                                </div>
                            </div>

                            <!-- <div class="col-md-6 form-group">
                                <label class="col-form-label">Category</label>
                                <div class="form-input">
                                    <select class="form-select">
                                        <option>1</option>
                                        <option>2</option>
                                    </select>
                                </div>
                            </div> -->
                        </div>

                        <div v-if="selectedLookupToCreate === 'subsystem'" class="row">
                            <div class="col-md-6 form-group">
                                <label class="col-form-label">SubSystem Name</label>
                                <div class="form-input">
                                    <input v-model="newSubSystemName" class="form-control" />
                                </div>
                            </div>

                            <!-- <div class="col-md-6 form-group">
                                <label class="col-form-label">System</label>
                                <div class="form-input">
                                    <select class="form-select">
                                        <option>1</option>
                                        <option>2</option>
                                    </select>
                                </div>
                            </div> -->
                        </div>

                        <div v-if="selectedLookupToCreate === 'style'" class="row">
                            <div class="col-md-6 form-group">
                                <label class="col-form-label">Style Name</label>
                                <div class="form-input">
                                    <input v-model="newStyleName" class="form-control" />
                                </div>
                            </div>

                            <!-- <div class="col-md-6 form-group">
                                <label class="col-form-label">Sub System</label>
                                <div class="form-input">
                                    <select class="form-select">
                                        <option>1</option>
                                        <option>2</option>
                                    </select>
                                </div>
                            </div> -->
                        </div>

                        <div v-if="selectedLookupToCreate === 'ownerType'" class="row">
                            <div class="col-md-6 form-group">
                                <label class="col-form-label">Owner Name</label>
                                <div class="form-input">
                                    <input v-model="newOwnerTypeName" class="form-control" />
                                </div>
                            </div>
                        </div>

                        <div v-if="selectedLookupToCreate === 'manufacturer'" class="row">
                            <div class="col-md-6 form-group">
                                <label class="col-form-label">Manufacturer Name</label>
                                <div class="form-input">
                                    <input v-model="newManufacturerName" class="form-control" />
                                </div>
                            </div>
                            <div class="col-md-6 form-group">
                                <label class="col-form-label">Website</label>
                                <div class="form-input">
                                    <input v-model="newManufacturerWebUrl" class="form-control" />
                                </div>
                            </div>

                            <div class="col-md-6 form-group">
                                <label class="col-form-label">Phone</label>
                                <div class="form-input">
                                    <input v-model="newManufacturerPhone" class="form-control" />
                                </div>
                            </div>

                            <div class="col-md-6 form-group">
                                <label class="col-form-label">Email</label>
                                <div class="form-input">
                                    <input v-model="newManufacturerEmail" class="form-control" />
                                </div>
                            </div>

                            <div class="col-md-6 form-group">
                                <label class="col-form-label">Address</label>
                                <div class="form-input">
                                    <input v-model="newManufacturerAddress" class="form-control" />
                                </div>
                            </div>
                        </div>

                        <div v-if="selectedLookupToCreate === 'pricingUnit'" class="row">
                            <div class="col-md-6 form-group">
                                <label class="col-form-label">Pricing Name</label>
                                <div class="form-input">
                                    <input v-model="newPricingName" class="form-control" />
                                </div>
                            </div>

                            <div class="col-md-6 form-group">
                                <label class="col-form-label">Pricing Equivalent</label>
                                <div class="form-input">
                                    <input v-model="newPricingEquivalent" class="form-control" />
                                </div>
                            </div>
                        </div>

                        <div v-if="selectedLookupToCreate === 'costCode'" class="row">
                            <div class="col-md-6 form-group">
                                <label class="col-form-label">Cost Code Name</label>
                                <div class="form-input">
                                    <input v-model="newCostCodeName" class="form-control" />
                                </div>
                            </div>

                            <div class="col-md-6 form-group">
                                <label class="col-form-label">Cost Code Abbreviation</label>
                                <div class="form-input">
                                    <input v-model="newCostCodeAbbreviation" class="form-control" />
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="modal-footer">
                        <!-- <button class="btn btn-secondary" type="button" data-bs-dismiss="modal">
                            Close
                        </button> -->
                        <button @click="handleCreateLookup" class="btn btn-primary" type="button">Save</button>
                    </div>
                </div>
            </div>
        </div>

    </div>

    <!-- <div class="database-container p-20">

        <h3 class="mb-3">
            Database
        </h3>

        <div class="mt-5">
            <ul class="nav nav-tabs nav-primary" id="pills-warningtab" role="tablist">
                <li class="nav-item"><a class="nav-link active" id="pills-warninghome-tab" data-bs-toggle="pill"
                        href="#pills-warninghome" role="tab" aria-controls="pills-warninghome" aria-selected="true">Part
                        Database</a></li>
                <li class="nav-item"><a class="nav-link" id="pills-warningprofile-tab" data-bs-toggle="pill"
                        href="#pills-warningprofile" role="tab" aria-controls="pills-warningprofile"
                        aria-selected="false">Element Database</a></li>
                <li class="nav-item"><a class="nav-link" id="pills-warningcontact-tab" data-bs-toggle="pill"
                        href="#pills-warningcontact" role="tab" aria-controls="pills-warningcontact"
                        aria-selected="false">Bundle Database</a></li>
            </ul>
            <div class="tab-content mt-3" id="pills-warningtabContent">
                <div class="tab-pane fade show active" id="pills-warninghome" role="tabpanel"
                    aria-labelledby="pills-warninghome-tab">
                    <a-table :columns="partColumns" :data-source="partData" />
                </div>
                <div class="tab-pane fade" id="pills-warningprofile" role="tabpanel"
                    aria-labelledby="pills-warningprofile-tab">
                    <a-table :columns="elementColumns" :data-source="elementData" />
                </div>
                <div class="tab-pane fade" id="pills-warningcontact" role="tabpanel"
                    aria-labelledby="pills-warningcontact-tab">
                    <p class="mb-0 m-t-30">Lore
                    </p>
                </div>
            </div>
        </div>

        <div class="mt-5 table-config">

            <h4 class="mb-3">
                Visibility Options
            </h4>
            
            <div class="table-config-list mt-3">
                <div class="row">
                <h5 class="mb-3">
                    Details
                </h5>
                <div class="col-md-2">
                    <div class="checkbox p-0">
                        <input id="checkbox1" type="checkbox" />
                        <label for="checkbox1">Name</label>
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="checkbox p-0">
                        <input id="checkbox1" type="checkbox" />
                        <label for="checkbox1">Owner Type</label>
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="checkbox p-0">
                        <input id="checkbox1" type="checkbox" />
                        <label for="checkbox1">Manufacturer</label>
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="checkbox p-0">
                        <input id="checkbox1" type="checkbox" />
                        <label for="checkbox1">Manufacturer Number</label>
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="checkbox p-0">
                        <input id="checkbox1" type="checkbox" />
                        <label for="checkbox1">UPC</label>
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="checkbox p-0">
                        <input id="checkbox1" type="checkbox" />
                        <label for="checkbox1">Photo</label>
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="checkbox p-0">
                        <input id="checkbox1" type="checkbox" />
                        <label for="checkbox1">Cut Sheet</label>
                    </div>
                </div>

                <div class="col-md-2">
                    <div class="checkbox p-0">
                        <input id="checkbox1" type="checkbox" />
                        <label for="checkbox1">Cost Code</label>
                    </div>
                </div>

                <div class="col-md-2">
                    <div class="checkbox p-0">
                        <input id="checkbox1" type="checkbox" />
                        <label for="checkbox1">Category</label>
                    </div>
                </div>

                <div class="col-md-2">
                    <div class="checkbox p-0">
                        <input id="checkbox1" type="checkbox" />
                        <label for="checkbox1">System</label>
                    </div>
                </div>

                <div class="col-md-2">
                    <div class="checkbox p-0">
                        <input id="checkbox1" type="checkbox" />
                        <label for="checkbox1">Sub-System</label>
                    </div>
                </div>

                <div class="col-md-2">
                    <div class="checkbox p-0">
                        <input id="checkbox1" type="checkbox" />
                        <label for="checkbox1">Style</label>
                    </div>
                </div>

                <div class="col-md-2">
                    <div class="checkbox p-0">
                        <input id="checkbox1" type="checkbox" />
                        <label for="checkbox1">Version Number</label>
                    </div>
                </div>
            </div>
            </div>

            <div class="table-config-list mt-3">
                <div class="row">
                <h5 class="mb-3">
                    Pricing
                </h5>
                <div class="col-md-2">
                    <div class="checkbox p-0">
                        <input id="checkbox1" type="checkbox" />
                        <label for="checkbox1">Last Pricing Update</label>
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="checkbox p-0">
                        <input id="checkbox1" type="checkbox" />
                        <label for="checkbox1">Book Price</label>
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="checkbox p-0">
                        <input id="checkbox1" type="checkbox" />
                        <label for="checkbox1">Book Price Units</label>
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="checkbox p-0">
                        <input id="checkbox1" type="checkbox" />
                        <label for="checkbox1">Price 1</label>
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="checkbox p-0">
                        <input id="checkbox1" type="checkbox" />
                        <label for="checkbox1">Price 1 Units</label>
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="checkbox p-0">
                        <input id="checkbox1" type="checkbox" />
                        <label for="checkbox1">Price 2</label>
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="checkbox p-0">
                        <input id="checkbox1" type="checkbox" />
                        <label for="checkbox1">Price 2 Units</label>
                    </div>
                </div>

                <div class="col-md-2">
                    <div class="checkbox p-0">
                        <input id="checkbox1" type="checkbox" />
                        <label for="checkbox1">Price 3</label>
                    </div>
                </div>

                <div class="col-md-2">
                    <div class="checkbox p-0">
                        <input id="checkbox1" type="checkbox" />
                        <label for="checkbox1">Price 3 Units</label>
                    </div>
                </div>

            </div>
            </div>

            <div class="table-config-list mt-3">
                <div class="row">
                <h5 class="mb-3">
                    Labor
                </h5>
                <div class="col-md-2">
                    <div class="checkbox p-0">
                        <input id="checkbox1" type="checkbox" />
                        <label for="checkbox1">Last Pricing Update</label>
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="checkbox p-0">
                        <input id="checkbox1" type="checkbox" />
                        <label for="checkbox1">Bid Labor</label>
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="checkbox p-0">
                        <input id="checkbox1" type="checkbox" />
                        <label for="checkbox1">Bid Labor Units</label>
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="checkbox p-0">
                        <input id="checkbox1" type="checkbox" />
                        <label for="checkbox1">CO Labor 1</label>
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="checkbox p-0">
                        <input id="checkbox1" type="checkbox" />
                        <label for="checkbox1">CO Labor 1 Units</label>
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="checkbox p-0">
                        <input id="checkbox1" type="checkbox" />
                        <label for="checkbox1">CO Labor 2</label>
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="checkbox p-0">
                        <input id="checkbox1" type="checkbox" />
                        <label for="checkbox1">CO Labor 2 Units</label>
                    </div>
                </div>

                <div class="col-md-2">
                    <div class="checkbox p-0">
                        <input id="checkbox1" type="checkbox" />
                        <label for="checkbox1">NECA 1</label>
                    </div>
                </div>

                <div class="col-md-2">
                    <div class="checkbox p-0">
                        <input id="checkbox1" type="checkbox" />
                        <label for="checkbox1">NECA 1 Units</label>
                    </div>
                </div>

                <div class="col-md-2">
                    <div class="checkbox p-0">
                        <input id="checkbox1" type="checkbox" />
                        <label for="checkbox1">NECA 2</label>
                    </div>
                </div>

                <div class="col-md-2">
                    <div class="checkbox p-0">
                        <input id="checkbox1" type="checkbox" />
                        <label for="checkbox1">NECA 2 Units</label>
                    </div>
                </div>

                <div class="col-md-2">
                    <div class="checkbox p-0">
                        <input id="checkbox1" type="checkbox" />
                        <label for="checkbox1">NECA 3</label>
                    </div>
                </div>

                <div class="col-md-2">
                    <div class="checkbox p-0">
                        <input id="checkbox1" type="checkbox" />
                        <label for="checkbox1">NECA 3 Units</label>
                    </div>
                </div>

            </div>
            </div>

        </div>

    </div> -->
</template>

<script setup>
import { ref, onBeforeMount } from "vue";
import {
    createCategory, createSystem, createSubSystem, createStyle,
    getAllOwnerType,
    getAllPricingUnits,
    getAllManufacturers,
    getAllCostCode,
    createCostCode,
    createPricingUnit,
    createManufacturer,
    createOwnerType,
    getAllCategories,
    getSystemsByCategoryId,
    getStylesBySubSystemId,
    getSubSystemsBySystemId,
    createPartService,
    getAllParts,
    createElementService,
    getAllElements,
    createBundleService,
    getAllBundles,
    createGroupService,
    updateLaborCost,
    updateLaborPrice,
    UpdateCostAndPriceByPercentage
} from "@/services/database"

import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';

import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.css";

import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import { AgGridVue } from "ag-grid-vue3"; // Vue Data Grid Component



const colDefsPart = ref([
  { field: "name", flex: 5 },
  { field: "cutSheetUrl" },
  { field: "manufacturerPartNumber" },
  { field: "upc" },
  { field: "versionNumber"}
]);

const colDefsElement = ref([
  { field: "name", flex: 5 },
  { field: "inventoryNumber" },
  { field: "versionNumber" },
  { field: "size" },
  { field: "notes"}
]);

const colDefsBundle = ref([
  { field: "name", flex: 5 },
  { field: "objectFileLocationUrl" },
  { field: "inventoryNumber" },
  { field: "size" },
  { field: "notes"}
]);


const updateLaborCostPart = ref("")
const updateLaborCostBidLabor = ref("")
const updateLaborCostBidLaborUnitID = ref("")
const updateLaborCostCoLabor1 = ref("")
const updateLaborCostCoLabor1Unit = ref("")
const updateLaborCostCoLabor2 = ref("")
const updateLaborCostCoLabor2Unit = ref("")
const updateLaborCostNeca1 = ref("")
const updateLaborCostNeca1Unit = ref("")
const updateLaborCostNeca2 = ref("")
const updateLaborCostNeca2Unit = ref("")
const updateLaborCostNeca3 = ref("")
const updateLaborCostNeca3Unit = ref("")

const updateLaborPricePart = ref("")
const updateLaborPriceBookPrice = ref("")
const updateLaborPriceBookPriceUnit = ref("")
const updateLaborPrice1 = ref("")
const updateLaborPrice1Unit = ref("")
const updateLaborPrice2 = ref("")
const updateLaborPrice2Unit = ref("")
const updateLaborPrice3 = ref("")
const updateLaborPrice3Unit = ref("")

const updateCostPricePercentage = ref("")

const isUpdateLaborCost = ref(1)
const isUpdateLaborPrice = ref(1)
const isUpdateLaborPriceIncrease = ref(1)

const isLoading = ref(false);
const selectedLookup = ref('demo');
const isAddNewLookup = ref(false)
const selectedChildLookup = ref('');
const showSystemTile = ref(true);
const showSubSystemTile = ref(true);
const showStyleTile = ref(true);
const showOwnerTypeTile = ref(false);
const showPricingTile = ref(false);
const showManufacturerTile = ref(false);
const showCostCodeTile = ref(false);

const selectedCategory = ref('');
const selectedSystem = ref('');
const selectedSubSystem = ref('');
const selectedStyle = ref('');

const selectedSystemsList = ref('');
const selectedSubSystemsList = ref('');
const selectedStyleList = ref('');

const selectedLookupToCreate = ref('');

const newCategoryName = ref('');
const newSystemName = ref('');
const newSubSystemName = ref('');
const newStyleName = ref('');
const newOwnerTypeName = ref('');

const newManufacturerName = ref('');
const newManufacturerWebUrl = ref('');
const newManufacturerPhone = ref('');
const newManufacturerEmail = ref('');
const newManufacturerAddress = ref('');

const newPricingName = ref('');
const newPricingEquivalent = ref('');

const newCostCodeName = ref('');
const newCostCodeAbbreviation = ref('');

const selectedParentLookup = ref(false);
const ownerTypeData = ref([]);
const pricingUnitsData = ref([]);
const manufacturerData = ref([]);
const costCodeData = ref([]);

const categoriesData = ref([])



const newPartName = ref("")
const newPartUpc = ref("")
const newPartCutSheetUrl = ref("")
const newPartManufacturerPartNumber = ref("")
const newPartVersionNumber = ref("")
const newPartOwnerType = ref("")
const newPartCostCode = ref("")
const newPartCategory = ref("")
const newPartSystem = ref("")
const newPartSubSystem = ref("")
const newPartStyle = ref("")
const newPartImages = ref([])
const newPartRFA = ref([])

const allPartsData = ref([])

const newElementName = ref("")
const newElementVersionNumber = ref("")
const newElementDescription = ref("")
const newElementSize = ref("")
const newElementNotes = ref("")
const newElementInventoryNumber = ref("")
const newElementPartOwnerTypeId = ref("")
const newElementObjectFileLocationUrl = ref("")
const newElementParts = ref([])
const newElementImages = ref([])

const allElementsData = ref([])

const newBundleName = ref("")
const newBundleSize = ref("")
const newBundleInventoryNumber = ref("")
const newBundlePartOwnerTypeId = ref("")
const newBundleObjectFileLocationUrl = ref("")
const newBundleDescription = ref("")
const newBundleNotes = ref("")
const newBundleElements = ref([])
const newBundleImages = ref([])

const allBundlesData = ref([])

const newGroupName = ref("")
const newGroupSize = ref("")
const newGroupPartOwnerTypeId = ref("")
const newGroupDescription = ref("")
const newGroupNotes = ref("")
const newGroupBundles = ref([])



onBeforeMount(() => {
    fetchAllBundles()
    fetchAllParts()
    fetchAllElements()
    fetchAllOwnerType()
    fetchAllPricingUnits()
    fetchAllManufacturers()
    fetchAllCostCode()
    fetchAllCategories()
})

const handleSwitchUpdateLaborCost = (param) => {
    isUpdateLaborCost.value = param
}

const handleSwitchUpdateLaborPrice = (param) => {
    isUpdateLaborPrice.value = param
}

const handleSwitchUpdateLaborPriceIncrease = (param) => {
    isUpdateLaborPriceIncrease.value = param
}


const handleUpdateLaborCost = () => {
const payload = {
    partId: updateLaborCostPart.value,
    bidLabor: updateLaborCostBidLabor.value,
    bidLaborUnitId: updateLaborCostBidLaborUnitID.value,
  colabor1: updateLaborCostCoLabor1.value,
  colabor1UniteId: updateLaborCostCoLabor1Unit.value,
  colabor2: updateLaborCostCoLabor2.value,
  colabor2UniteId: updateLaborCostCoLabor2Unit.value,
  neca1: updateLaborCostNeca1.value,
  neca1uniteId: updateLaborCostNeca1Unit.value,
  neca2: updateLaborCostNeca2.value,
  neca2uniteId: updateLaborCostNeca2Unit.value,
  neca3: updateLaborCostNeca3.value,
  neca3uniteId: updateLaborCostNeca3Unit.value
};
    updateLaborCost(payload)
        .then(res => {
            toast.success('Successfully Updated');
            console.log('res', res)
        })
        .catch(err => {
            toast.success('Something went wrong');
            console.error(err)
        })
}

const handleUpdateLaborPrice = () => {
const payload = {
    partId: updateLaborPricePart.value,
    bookPrice: updateLaborPriceBookPrice.value,
    bookPriceUnitId: updateLaborPriceBookPriceUnit.value,
    price1: updateLaborPrice1.value,
    price1UnitId: updateLaborPrice1Unit.value,
    price2: updateLaborPrice2.value,
    price2UnitId: updateLaborPrice2Unit.value,
    price3: updateLaborPrice3.value,
    price3UnitId: updateLaborPrice3Unit.value,
};
updateLaborPrice(payload)
        .then(res => {
            toast.success('Successfully Updated');
            console.log('res', res)
        })
        .catch(err => {
            toast.success('Something went wrong');
            console.error(err)
        })
}

const handleUpdateCostAndPriceByPercentage = () => {
const payload = {
    percentage: parseInt(updateCostPricePercentage.value),
    updateLaborCost: isUpdateLaborCost.value === 1,
  updatePrice: isUpdateLaborPrice.value === 1,
  isIncrease: isUpdateLaborPriceIncrease.value === 1
};
UpdateCostAndPriceByPercentage(payload)
        .then(res => {
            toast.success('Successfully Updated');
            console.log('res', res)
        })
        .catch(err => {
            toast.success('Something went wrong');
            console.error(err)
        })
}

const handleCreatePart = () => {
    isLoading.value = true
    const partFormData = new FormData();
    partFormData.append("Name", newPartName.value)
    partFormData.append("ManufacturerPartNumber", newPartManufacturerPartNumber.value)
    partFormData.append("Upc", newPartUpc.value)
    partFormData.append("CutSheetUrl", newPartCutSheetUrl.value)
    partFormData.append("VersionNumber", newPartVersionNumber.value)
    partFormData.append("OwnerTypeId", newPartOwnerType.value)
    partFormData.append("CostCodeId", newPartCostCode.value)
    partFormData.append("CategoryId", newPartCategory.value)
    partFormData.append("SystemId", newPartSystem.value)
    partFormData.append("SubSystemId", newPartSubSystem.value)
    partFormData.append("StyleId", newPartStyle.value)
    // partFormData.append("Images", newPartImages.value)
    for (var x = 0; x < newPartImages.value.length; x++) {
        partFormData.append("Images", newPartImages.value[x]);
    }

    for (var x = 0; x < newPartRFA.value.length; x++) {
        partFormData.append("RFAFile", newPartRFA.value[x]);
    }
    createPartService(partFormData, newPartManufacturerPartNumber.value)
        .then(res => {
            fetchAllParts()
            isLoading.value = false
            toast.success('New Part Created');
            console.log('res', res)
        })
        .catch(err => {
            isLoading.value = false
            toast.error('Something Went Wrong');
            console.error(err)
        })
}

const handleCreateElement = () => {
    isLoading.value = true
    const getPartIds = newElementParts.value.map(item => item.id)
    const elementFormData = new FormData();
    elementFormData.append("Name", newElementName.value)
    elementFormData.append("VersionNumber", newElementVersionNumber.value)
    elementFormData.append("Description", newElementDescription.value)
    elementFormData.append("Size", newElementSize.value)
    elementFormData.append("Notes", newElementNotes.value)
    elementFormData.append("InventoryNumber", newElementInventoryNumber.value)
    elementFormData.append("PartOwnerTypeId", newElementPartOwnerTypeId.value)
    elementFormData.append("ObjectFileLocationUrl", newElementObjectFileLocationUrl.value)
    // elementFormData.append("ElementSymbolPictures", newElementImages.value)
    for (var x = 0; x < newElementImages.value.length; x++) {
        elementFormData.append("ElementSymbolPictures", newElementImages.value[x]);
    }

    for (var x = 0; x < getPartIds.length; x++) {
        elementFormData.append("PartsIds", getPartIds[x]);
    }
    createElementService(elementFormData)
        .then(res => {
            fetchAllElements()
            isLoading.value = false
            toast.success('New Element Created');
            console.log('res', res)
        })
        .catch(err => {
            isLoading.value = false
            toast.error('Something Went Wrong');
            console.error(err)
        })
}

const handleCreateBundle = () => {
    isLoading.value = true
    const getElementsIds = newBundleElements.value.map(item => item.id)
    const bundleFormData = new FormData();
    bundleFormData.append("Name", newBundleName.value)
    bundleFormData.append("Description", newBundleDescription.value)
    bundleFormData.append("Size", newBundleSize.value)
    bundleFormData.append("Notes", newBundleNotes.value)
    bundleFormData.append("InventoryNumber", newBundleInventoryNumber.value)
    bundleFormData.append("PartOwnerTypeId", newBundlePartOwnerTypeId.value)
    bundleFormData.append("ObjectFileLocationUrl", newBundleObjectFileLocationUrl.value)
    // bundleFormData.append("BundleSymbolPictures", newBundleImages.value)
    for (var x = 0; x < newBundleImages.value.length; x++) {
        bundleFormData.append("BundleSymbolPictures", newBundleImages.value[x]);
        console.log("newBundleImages.value[x]", newBundleImages.value[x])
    }

    for (var x = 0; x < getElementsIds.length; x++) {
        bundleFormData.append("ElementsIds", getElementsIds[x]);
    }
    createBundleService(bundleFormData)
        .then(res => {
            fetchAllBundles()
            isLoading.value = false
            toast.success('New Bundle Created');
            console.log('res', res)
        })
        .catch(err => {
            isLoading.value = false
            toast.error('Something Went Wrong');
            console.error(err)
        })
}

const handleCreateGroup = () => {
    isLoading.value = true
    const bundlePayload = {
        "name": newGroupName.value,
        "description": newGroupDescription.value,
        "size": newGroupSize.value,
        "notes": newGroupNotes.value,
        "partOwnerTypeId": newGroupPartOwnerTypeId.value,
        "bundleIds": newGroupBundles.value.map(item => item.id)
    }

    createGroupService(bundlePayload)
        .then(res => {
            isLoading.value = false
            toast.success('New Group Created');
            console.log('res', res)
        })
        .catch(err => {
            isLoading.value = false
            toast.error('Something Went Wrong');
            console.error(err)
        })
}

const onFileChange = (e) => {
    var files = e.target.files || e.dataTransfer.files;
    if (!files.length)
        return;
    newPartImages.value = files
    console.log("newPartImages", newPartImages.value)
}

const onFileChangeRFA = (e) => {
    var files = e.target.files || e.dataTransfer.files;
    if (!files.length)
        return;
    newPartRFA.value = files
    console.log("newPartRFA", newPartRFA.value)
}

const onFileChangeElement = (e) => {
    var files = e.target.files || e.dataTransfer.files;
    if (!files.length)
        return;
    newElementImages.value = files
    console.log("newElementImages", newElementImages.value)
}

const onFileChangeBundle = (e) => {
    var files = e.target.files || e.dataTransfer.files;
    if (!files.length)
        return;
    newBundleImages.value = files
    console.log("newBundleImages", newElementImages.value)
}

const fetchAllCategories = () => {
    isLoading.value = true
    getAllCategories()
        .then(res => {
            isLoading.value = false
            categoriesData.value = res.items
            console.log('res', res)
        })
        .catch(err => {
            isLoading.value = false
            console.error(err)
        })
}

const fetchAllParts = () => {
    getAllParts()
        .then(res => {
            allPartsData.value = res
            console.log('res', res)
        })
        .catch(err => {
            console.error(err)
        })
}

const fetchAllBundles = () => {
    getAllBundles()
        .then(res => {
            allBundlesData.value = res
            console.log('res', res)
        })
        .catch(err => {
            console.error(err)
        })
}

const fetchAllElements = () => {
    getAllElements()
        .then(res => {
            allElementsData.value = res
            console.log('res', res)
        })
        .catch(err => {
            console.error(err)
        })
}

const fetchAllOwnerType = () => {
    getAllOwnerType()
        .then(res => {
            ownerTypeData.value = res.items
            console.log('res', res)
        })
        .catch(err => {
            console.error(err)
        })
}

const fetchAllPricingUnits = () => {
    getAllPricingUnits()
        .then(res => {
            pricingUnitsData.value = res.items
            console.log('res', res)
        })
        .catch(err => {
            console.error(err)
        })
}

const fetchAllManufacturers = () => {
    getAllManufacturers()
        .then(res => {
            manufacturerData.value = res.items
            console.log('res', res)
        })
        .catch(err => {
            console.error(err)
        })
}

const fetchAllCostCode = () => {
    getAllCostCode()
        .then(res => {
            costCodeData.value = res.items
            console.log('res', res)
        })
        .catch(err => {
            console.error(err)
        })
}

const selectLookup = (lookup) => {
    if (lookup === 'demo') {
        selectedParentLookup.value = false
    }
    else {
        selectedParentLookup.value = true
        selectedLookup.value = lookup
    }
}


const selectChildLookup = (childlookup) => {
    console.log("child", childlookup)
    selectedChildLookup.value = childlookup
}

const addNewLookup = () => {
    isAddNewLookup.value = true
}

const goBack = () => {
    isAddNewLookup.value = false
}


const handleCreateLookup = () => {
    if (selectedLookupToCreate.value === 'category') {
        handleCreateCategory()
    }
    if (selectedLookupToCreate.value === 'system') {
        handleCreateSystem()
    }
    if (selectedLookupToCreate.value === 'subsystem') {
        handleCreateSubSystem()
    }
    if (selectedLookupToCreate.value === 'style') {
        handleCreateStyle()
    }
    if (selectedLookupToCreate.value === 'ownerType') {
        handleCreateOwnerType()
    }
    if (selectedLookupToCreate.value === 'manufacturer') {
        handleCreateManufacturer()
    }
    if (selectedLookupToCreate.value === 'pricingUnit') {
        handleCreatePricingUnit()
    }
    if (selectedLookupToCreate.value === 'costCode') {
        handleCreateCostCode()
    }
}

const handleCreateCategory = () => {
    const categoryPayload = {
        name: newCategoryName.value
    }
    createCategory(categoryPayload)
        .then(res => {
            console.log('res', res)
            toast.success('Category Created');
            fetchAllCategories()
        })
        .catch(err => {
            console.error(err)
        })
}

const handleCreateSystem = () => {
    const systemPayload = {
        name: newSystemName.value,
        categoryId: selectedCategory.value
    }
    createSystem(systemPayload)
        .then(res => {
            console.log('res', res)
            handleClickCategory(selectedCategory.value)
            toast.success('System Created');
        })
        .catch(err => {
            console.error(err)
        })
}

const handleCreateSubSystem = () => {
    const subSystemPayload = {
        name: newSubSystemName.value,
        systemId: selectedSystem.value
    }
    createSubSystem(subSystemPayload)
        .then(res => {
            console.log('res', res)
            handleClickSystem(selectedSystem.value)
            toast.success('Sub System Created');
        })
        .catch(err => {
            console.error(err)
        })
}

const handleCreateStyle = () => {
    const stylePayload = {
        name: newStyleName.value,
        subSystemId: selectedSubSystem.value
    }
    createStyle(stylePayload)
        .then(res => {
            console.log('res', res)
            handleClickSubsystem(selectedSubSystem.value)
            toast.success('Style Created');
        })
        .catch(err => {
            console.error(err)
        })
}

const handleCreateOwnerType = () => {
    const ownerTypePayload = {
        name: newOwnerTypeName.value,
    }
    createOwnerType(ownerTypePayload)
        .then(res => {
            fetchAllOwnerType()
            toast.success('Owner Type Created');
        })
        .catch(err => {
            console.error(err)
        })
}

const handleCreateManufacturer = () => {
    const manufacturerPayload = {
        "name": newManufacturerName.value,
        "webSiteUrl": newManufacturerWebUrl.value,
        "phone": newManufacturerPhone.value,
        "email": newManufacturerEmail.value,
        "address": newManufacturerAddress.value
    }
    createManufacturer(manufacturerPayload)
        .then(res => {
            fetchAllManufacturers()
            toast.success('Manufacturer Created');
        })
        .catch(err => {
            console.error(err)
        })
}

const handleCreatePricingUnit = () => {
    const pricingPayload = {
        name: newPricingName.value,
        equivalent: newPricingEquivalent.value
    }
    createPricingUnit(pricingPayload)
        .then(res => {
            fetchAllPricingUnits()
            toast.success('Pricing unit Created');
        })
        .catch(err => {
            console.error(err)
        })
}

const handleCreateCostCode = () => {
    const costCodePayload = {
        name: newCostCodeName.value,
        abbreviation: newCostCodeAbbreviation.value
    }
    createCostCode(costCodePayload)
        .then(res => {
            fetchAllCostCode()
            toast.success('Cost code Created');
        })
        .catch(err => {
            console.error(err)
        })
}

const createNewLookup = (lookup) => {
    selectedLookupToCreate.value = lookup
}

const addSystem = () => {
    console.log("log")
}

const addSubSystem = () => {
    console.log("log")
}

const addStyle = () => {
    console.log("log")
}

const handleClickCategory = (id) => {
    isLoading.value = true
    selectedCategory.value = id
    getSystemsByCategoryId(selectedCategory.value)
        .then(res => {
            isLoading.value = false
            selectedSystemsList.value = res
            console.log('res', res)
        })
        .catch(err => {
            isLoading.value = false
            console.error(err)
        })
    //selectedSystemsList.value = systemsDataMock.filter((x) => x.categoryID === id)
    selectedSubSystemsList.value = []
    selectedStyleList.value = []
    // showSystemTile.value = true
}
const handleClickSystem = (id) => {
    isLoading.value = true
    selectedSystem.value = id
    getSubSystemsBySystemId(selectedSystem.value)
        .then(res => {
            isLoading.value = false
            selectedSubSystemsList.value = res
            console.log('res', res)
        })
        .catch(err => {
            isLoading.value = false
            console.error(err)
        })

    //selectedSubSystemsList.value = subSystemsDataMock.filter((x) => x.systemID === id)
    selectedStyleList.value = []
    // showSubSystemTile.value = true
}
const handleClickSubsystem = (id) => {
    isLoading.value = true
    selectedSubSystem.value = id
    getStylesBySubSystemId(selectedSubSystem.value)
        .then(res => {
            isLoading.value = false
            selectedStyleList.value = res
            console.log('res', res)
        })
        .catch(err => {
            isLoading.value = false
            console.error(err)
        })

    // selectedStyleList.value = styleDataMock.filter((x) => x.subSystemID === id)
    // showStyleTile.value = true
}
</script>

<style lang="scss" scoped>
.database-container {
    .nav-tabs {
        border-bottom-color: #ccc;
    }
}

.table-config {
    // background: #fff;
    // box-shadow: 0 0 5px #ccc;
    // padding: 20px;
    margin-top: 30px;
}

.table-config-list {
    background: #EAEAEA;
    padding: 20px;
    margin-top: 30px;
    border-radius: 8px;
    border: 1px solid #ccc;
}

.lookup-list {
    background-color: rgb(240, 240, 240);
    padding: 15px 5px 15px 15px;
}

.lookup-detail-area {
    padding: 15px 20px 15px 0;
}

.active-accordion {
    background: #FFF;
    padding: 15px;
    border-left: 3px solid #1c69ab;
    margin-bottom: 15px;
}

.database-tile {
    box-shadow: rgba(0, 0, 0, 0.133) 0px 1.6px 3.6px 0px, rgba(0, 0, 0, 0.11) 0px 0.3px 0.9px 0px;
    background-color: #fff;
    padding: 20px;
    margin-top: 10px;
    border-top: 3px solid #1c69ab;
    min-height: 300px;
    max-height: 300px;
    overflow-y: auto;
}

.new-part-image {
    input[type="file"] {
        display: none;
    }

    .custom-file-upload {
        color: #fff !important;
        display: inline-block;
        padding: 6px 12px;
        cursor: pointer;
        margin-top: 20px;
    }
}
.custom-switch {
  border: 1px solid #ccc;
  border-radius: 30px;
  padding: 8px 5px;
  display: inline-block;
  margin-top: 4px;
  background-color: aliceblue;

  span {
    padding: 5px 15px;
    cursor: pointer;
    border-radius: 30px;

    &.active {
      background-color: #1c69ab;
      color: #fff;
    }
  }
}

</style>