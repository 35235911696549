<template>
        <div v-if="isLoading" class="overlay-loader-search"></div>
  
       <!-- Forge Viewer Container -->
       <div id="MyViewerDiv" @dragover="onDragOver"
      @drop="onDrop"></div>

<!-- Button Container -->
<div class="controls">
    <div class="search-container mt-5 mb-5">
    <!-- Search Bar -->
    <div class="search-bar">
      <input type="text" v-model="searchTerm" @input="onSearch" placeholder="Search..." />
      <button @click="toggleFilter">Filter</button>
      <button class="btn btn-secondary" @click="resetFilter">Reset</button>
    </div>

    <div v-if="selectedPartsToDrag.length > 0" class="dragged-objs d-flex">
        <div v-for="obj in selectedPartsToDrag" class="p-3"
        draggable="true"
        @click="onClickDragObj(obj)"
        @dragstart="onDragStart"
        >
           <img width="100" :src="obj.partPictures[0]?.url" />
        </div>
    </div>
    <button class="btn btn-secondary mt-3 d-flex" @click="submitModifyChanges">Save Changes</button>
    <!-- <button class="btn btn-secondary mt-3" @click="saveDbIdInArray">Get DBID</button> -->

    <!-- Dropdown with Search Results (only when searchTerm is not empty) -->
    <div v-if="searchTerm && isShowDropDown" class="dropdown search-results">
      <div>
        <h4>Parts</h4>
        <p v-if="results.parts?.length < 1" class="px-2">
          No Parts Found
        </p>
        <ul v-if="results.parts?.length">
          <li @click="setSearchValue(part)" v-for="part in results.parts" :key="part.id">{{ part.name }}</li>
        </ul>
      </div>

      <!-- Elements Group -->
      <div>
        <h4>Elements</h4>
        <p v-if="results.elements?.length < 1" class="px-2">
          No Elements Found
        </p>
        <ul v-if="results.elements?.length">
          <li @click="setSearchValue(element)" v-for="element in results.elements" :key="element.id">{{ element.name
          }}</li>
        </ul>
      </div>

      <!-- Bundles Group -->
      <div>
        <h4>Bundles</h4>
        <p v-if="results.bundles?.length < 1" class="px-2">
          No Bundles Found
        </p>
        <ul v-if="results.bundles?.length">
          <li @click="setSearchValue(bundle)" v-for="bundle in results.bundles" :key="bundle.id">{{ bundle.name }}
          </li>
        </ul>
      </div>
    </div>

    <!-- Filter Modal -->
    <div v-if="showFilterModal" class="filter-modal">
      <div class="filter-content">
        <h6>Type</h6>
        <div v-for="filter in filters" :key="filter.id">
          <label>
            <input type="checkbox" :value="filter.id" v-model="selectedFilters" />
            {{ filter.name }}
          </label>
        </div>

        <div>
          <h6>Elements</h6>
          <p v-if="elements.length < 1">
            No Elements Found
          </p>
          <div v-for="element in elements" :key="element.elementId">
            <label>
              <input type="checkbox" :value="element.elementId" v-model="selectedElementId" />
              {{ element.name }}
            </label>
          </div>
        </div>

        <div>
          <h6>Parts</h6>
          <p v-if="parts.length < 1">
            No Parts Found
          </p>
          <div v-for="part in parts" :key="part.id">
            <label>
              <input type="checkbox" :value="part.id" v-model="selectedPartId" />
              {{ part.name }}
            </label>
          </div>
        </div>

        <div>
          <h6>Bundles</h6>
          <p v-if="bundles.length < 1">
            No Parts Found
          </p>
          <div v-for="bundle in bundles" :key="bundle.id">
            <label>
              <input type="checkbox" :value="bundle.id" v-model="selectedBundleId" />
              {{ bundle.name }}
            </label>
          </div>
        </div>

        <div>
          <h6>Categories</h6>
          <div v-for="category in categories" :key="category.id">
            <label>
              <input type="checkbox" :value="category.id" v-model="selectedCategoryId" />
              {{ category.name }}
            </label>
          </div>
        </div>


        <!-- System Filter -->
        <div>
          <h6>Systems</h6>
          <p v-if="systems.length < 1">
            No Systems Found
          </p>
          <div v-for="system in systems" :key="system.id">
            <label>
              <input type="checkbox" :value="system.id" v-model="selectedSystemId" />
              {{ system.name }}
            </label>
          </div>
        </div>

        <div>
          <h6>Sub Systems</h6>
          <p v-if="subsystems.length < 1">
            No SubSystems Found
          </p>
          <div v-for="subsystem in subsystems" :key="subsystem.id">
            <label>
              <input @change="handleClickSystem" type="checkbox" :value="subsystem.id" v-model="selectedsubsystemId" />
              {{ subsystem.name }}
            </label>
          </div>
        </div>

        <div>
          <h6>Styles</h6>
          <p v-if="styles.length < 1">
            No Styles Found
          </p>
          <div v-for="style in styles" :key="style.id">
            <label>
              <input type="checkbox" :value="style.id" v-model="selectedStyleId" />
              {{ style.name }}
            </label>
          </div>
        </div>



        <button @click="applyFilters">Apply Filters</button>
      </div>
    </div>
  </div>
</div>


  <!-- <div id="MyContainerDiv">
    <div
      id="MyViewerDiv"
      @dragover="onDragOver"
      @drop="onDrop"
    ></div>
  </div> -->


</template>

<script setup>
import { ref, watch, inject, computed, onBeforeMount, onMounted, defineProps } from "vue"
import { getProjectModelsById } from "@/services/project"
import { placeRFAtoRVT } from "@/services/autodesk"

import axios from "axios"
import {
  getAllOwnerType,
  getAllPricingUnits,
  getAllManufacturers,
  getAllCostCode,
  getAllCategories,
  getAllSystems,
  getAllSubSystems,
  getAllStyles,
  getSystemsByCategoryId,
  getStylesBySubSystemId,
  getSubSystemsBySystemId,
  getAllParts,
  getAllElements,
  getAllBundles,
} from "@/services/database"


const props = defineProps({
  tabData: Object,
});

const isShowViewer = ref(false)
const takeOffProjectID = ref('')
const allURNsList = ref([])
const selectedPartsToDrag = ref([])
const allProjectModelsList = ref([])
const selectedPartToDrag = ref('')
const selectedPartToDragId = ref('')

const draggedPositionToSend = ref({})
const rotationToSend = ref({})
const arrayOfDraggedObjects = ref([])


const searchTerm = ref('');
const results = ref({
  parts: [],
  elements: [],
  bundles: [],
});

const categories = ref([]);
const systems = ref([]);
const subsystems = ref([]);
const styles = ref([]);
const elements = ref([]);
const parts = ref([]);
const bundles = ref([]);
const isLoading = ref(true);
const isShowDropDown = ref(false);


const selectedCategoryId = ref([]);
const selectedSystemId = ref([]);
const selectedsubsystemId = ref([]);
const selectedStyleId = ref([]);
const selectedPartId = ref([]);
const selectedElementId = ref([]);
const selectedBundleId = ref([]);

const filters = ref([
  { id: "All", name: "All" },
  { id: "Part", name: "Part" },
  { id: "Element", name: "Element" },
  { id: "Bundle", name: "Bundle" },
]);
const selectedFilters = ref([]);
const showFilterModal = ref(false);
const debounceTimeout = ref(null);

onBeforeMount(() => {

console.log("mounteddd")

  let urlParams = new URLSearchParams(window.location.search);
  if (urlParams.has('id')) {
    takeOffProjectID.value = urlParams.get('id')
  }
  else if (props.tabData?.projectId) {
    takeOffProjectID.value = props.tabData?.projectId
  }
  fetchProjectModelsById(takeOffProjectID.value)
  fetchAllBundles()
  fetchAllParts()
  fetchAllElements()
  // fetchAllOwnerType()
  // fetchAllPricingUnits()
  // fetchAllManufacturers()
  // fetchAllCostCode()
  fetchAllCategories()
  fetchAllSystems()
  fetchAllSubSystems()
  fetchAllStyles()
})

const saveDbIdInArray = () => {
  console.log("arrayOfDraggedObjects", arrayOfDraggedObjects)

  var instanceTree = viewer.model.getData().instanceTree;

  var allDbIdsStr = Object.keys(instanceTree.nodeAccess.dbIdToIndex);

  const allDBIDS = allDbIdsStr.map(function(id) { return parseInt(id)});

  allDBIDS.forEach(element => {
    const dbId = element; // Get the selected object's dbId


const rotation = getObjectRotation(viewer, dbId);

instanceTree.enumNodeFragments(dbId, (fragId) => {
console.log(dbId,fragId)
var bBox = getModifiedWorldBoundingBox(
[fragId],
viewer.model.getFragmentList()
);

console.log("bBox", bBox.center())

const xCor = bBox.center().x + viewer.model.getData().globalOffset.x

const yCor = bBox.center().y + viewer.model.getData().globalOffset.y

const zCor = bBox.center().z + viewer.model.getData().globalOffset.z

console.log("FINAL CORDS", xCor,yCor,zCor)
const finalDragObject = {
  x:xCor,
  y:yCor,
  z:zCor
}
draggedPositionToSend.value = finalDragObject
rotationToSend.value = rotation

console.log("Rotation (degrees):", rotation);

})


// var bBox = getModifiedWorldBoundingBox(
// event.selections[0].fragIdsArray,
// viewer.model.getFragmentList()
// );

// console.log("bBox", bBox.center())

// const xCor = bBox.center().x + viewer.model.getData().globalOffset.x

// const yCor = bBox.center().y + viewer.model.getData().globalOffset.y

// const zCor = bBox.center().z + viewer.model.getData().globalOffset.z

// console.log("FINAL CORDS", xCor,yCor,zCor)
// const finalDragObject = {
//   x:xCor,
//   y:yCor,
//   z:zCor
// }
// draggedPositionToSend.value = finalDragObject
// rotationToSend.value = rotation

// console.log("Rotation (degrees):", rotation);
  });


   

// var bBox2 = getOriginalWorldBoundingBox(
// event.selections[0].fragIdsArray,
// viewer.model.getFragmentList()
// );

// console.log("bBox2", bBox2.center())




  // const dbId = myDbId; // The dbId of the object you want to retrieve viewer.getProperties(dbId, (result) => {     console.log(result); // Contains properties like name, externalId, and other metadata });
 
}


const submitModifyChanges = () => {
  isLoading.value = true
  const modifiedPayload = {
  "projectModelId": allProjectModelsList.value[0].id,
  "partVersionCoordinates": [
    {
      "partVersionId": selectedPartToDragId.value,
      "x": draggedPositionToSend.value.x,
      "y": draggedPositionToSend.value.y,
      "z": draggedPositionToSend.value.z,
      "Angle": rotationToSend.value.z
    }
  ]
}
  placeRFAtoRVT(modifiedPayload)
    .then(res => {
      isLoading.value = false
      console.log('res', res)
    })
    .catch(err => {
      isLoading.value = false
      console.error(err)
    })
}

const fetchAllElements = () => {
  isLoading.value = true
  getAllElements()
    .then(res => {
      isLoading.value = false
      elements.value = res
      console.log('res', res)
    })
    .catch(err => {
      isLoading.value = false
      console.error(err)
    })
}

const fetchAllParts = () => {
  isLoading.value = true
  getAllParts()
    .then(res => {
      isLoading.value = false
      parts.value = res
      console.log('res', res)
    })
    .catch(err => {
      isLoading.value = false
      console.error(err)
    })
}

const fetchAllBundles = () => {
  isLoading.value = true
  getAllBundles()
    .then(res => {
      isLoading.value = false
      bundles.value = res
      console.log('res', res)
    })
    .catch(err => {
      isLoading.value = false
      console.error(err)
    })
}

const fetchAllSystems = () => {
  isLoading.value = true
  getAllSystems()
    .then(res => {
      isLoading.value = false
      systems.value = res.items
      console.log('res', res)
    })
    .catch(err => {
      isLoading.value = false
      console.error(err)
    })
}

const fetchAllSubSystems = () => {
  isLoading.value = true
  getAllSubSystems()
    .then(res => {
      isLoading.value = false
      subsystems.value = res.items
      console.log('res', res)
    })
    .catch(err => {
      isLoading.value = false
      console.error(err)
    })
}

const fetchAllStyles = () => {
  isLoading.value = true
  getAllStyles()
    .then(res => {
      isLoading.value = false
      styles.value = res.items
      console.log('res', res)
    })
    .catch(err => {
      isLoading.value = false
      console.error(err)
    })
}

// API Call function
const fetchResults = async (searchTerm) => {
  isShowDropDown.value = true
  isLoading.value = true
  const params = new URLSearchParams();
  console.log("searchTermsearchTerm", searchTerm)

  try {
    params.append("Keyword", searchTerm);
    // Append filters only if any are selected
    if (selectedFilters.value.length > 0) {
      selectedFilters.value.forEach((filter) => {
        params.append("FilterType", filter);
      });
    }

    if (selectedCategoryId.value) {
      selectedCategoryId.value.forEach((id) => {
        params.append("CategoryIds", id);
      });
    }
    if (selectedSystemId.value) {
      selectedSystemId.value.forEach((id) => {
        params.append("SystemIds", id);
      });
    }
    if (selectedsubsystemId.value) {
      selectedsubsystemId.value.forEach((id) => {
        params.append("SubSystemIds", id);
      });
    }
    if (selectedStyleId.value) {
      selectedStyleId.value.forEach((id) => {
        params.append("StyleIds", id);
      });
    }
    if (selectedElementId.value) {
      selectedElementId.value.forEach((id) => {
        params.append("ElementIds", id);
      });
    }
    if (selectedPartId.value) {
      selectedPartId.value.forEach((id) => {
        params.append("PartIds", id);
      });
    }
    if (selectedBundleId.value) {
      selectedBundleId.value.forEach((id) => {
        params.append("BundleIds", id);
      });
    }

    const response = await axios.get("https://estimating.candrelec.com/api/PartDbSearch", {
      params,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token_api')}` // Adding Authorization header
      },
    });
    isLoading.value = false
    results.value = response.data; // Adjust based on API response structure
  } catch (error) {
    isLoading.value = false
    console.error("API error:", error);
    results.value = []; // Clear results on error
  }
};

// Search function with debounce
const onSearch = () => {
  if (debounceTimeout.value) {
    clearTimeout(debounceTimeout.value);
  }

  // Wait 300ms after user stops typing before making API call
  debounceTimeout.value = setTimeout(() => {
    if (searchTerm.value) {
      fetchResults(searchTerm.value);
    } else {
      results.value = []; // Clear results if query is empty
    }
  }, 300);
};

// Toggle the filter modal
const toggleFilter = () => {
  showFilterModal.value = !showFilterModal.value;
};

const setSearchValue = (value) => {
  searchTerm.value = value.name
  isShowDropDown.value = false

  selectedPartsToDrag.value.push(value)
};

const resetFilter = () => {
  selectedCategoryId.value = [];
  selectedSystemId.value = [];
  selectedsubsystemId.value = [];
  selectedStyleId.value = [];
  selectedPartId.value = [];
  selectedElementId.value = [];
  selectedBundleId.value = [];
  selectedFilters.value = []
};

// Apply filters and re-fetch the results
const applyFilters = () => {
  showFilterModal.value = false;
  if (searchTerm.value) {
    fetchResults(searchTerm.value); // Re-fetch results with the applied filters
  }
};

const fetchAllCategories = () => {
  isLoading.value = true
  getAllCategories()
    .then(res => {
      isLoading.value = false
      categories.value = res.items
      console.log('res', res)
    })
    .catch(err => {
      isLoading.value = false
      console.error(err)
    })
}

const fetchProjectModelsById = (takeOffProjectID) => {
    getProjectModelsById(takeOffProjectID)
    .then(res => {
      console.log('res', res)
      allProjectModelsList.value = res
      allURNsList.value = res.map(item => ({ url: item.url }));

      startViewerModification()
    })
    .catch(err => {
      console.error(err)
    })
}

    // this is the iframe URL that shows up when sharing a model embed on a page
    var myRevitFile =
      "https://myhub.autodesk360.com/ue29c89b7/shares/public/SH7f1edQT22b515c761e81af7c91890bcea5?mode=embed"; // Revit file (A360/Forge/Napa.rvt)
    var myDwfxFile =
      "https://autodesk3743.autodesk360.com/shares/public/SH919a0QTf3c32634dcf03b8a55be243c021?mode=embed"; // Sports Car.dwfx

    var myOtherModelFile = ""

    var viewer;
    let mainModel = null;
    let secondModel = null;
    let extraZ = 0;
    

    document.onkeydown = event => {
      if (!event.shiftKey)
        return;

      if (event.code === "ArrowRight") {
        console.log("SEcond Model", secondModel)
        let tr = secondModel.getPlacementTransform();
        tr.elements[12] += 1;
        secondModel.setPlacementTransform(tr);
      }

      if (event.code === "ArrowLeft") {
        let tr = secondModel.getPlacementTransform();
        tr.elements[12] -= 1;
        secondModel.setPlacementTransform(tr);
      }
    };

    document.onmousemove = event => {
      if (!event.ctrlKey)
        return;

      let res = viewer.impl.hitTest(event.clientX, event.clientY, true, null, [mainModel.getModelId()]);
      let pt = null;

      if (res) {
        pt = res.intersectPoint;
      } else {
        pt = viewer.impl.intersectGround(event.clientX, event.clientY);
      }

      let tr = secondModel.getPlacementTransform();
      tr.elements[12] = pt.x;
      tr.elements[13] = pt.y;
      tr.elements[14] = pt.z + extraZ;
      secondModel.setPlacementTransform(tr);
      viewer.impl.invalidate(true, true, true);
          
      let coords = viewer.impl.clientToViewport(screenX, screenY); //c.Vector3 {x: -0.9696521095484826, y: 0.9200779727095516, z: 1 (always 1)}
      let finalCoords = coords.unproject(viewer.impl.camera) //c.Vector3 {x: -26.379134321221724, y: 5.162777223710702, z: 1.3846547842336627}
      // draggedPositionToSend.value  = finalCoords

    }


    

    const extensions = [
      "Autodesk.AEC.LevelsExtension",
      "Autodesk.DocumentBrowser",
      "Autodesk.AEC.Minimap3DExtension",
      "Autodesk.ADN.SheetsBrowserExt",
      "Autodesk.Viewing.SceneBuilder"
    ];

    function getURN(embedURLfromA360, type, onURNCallback) {
      // "dXJuOmFkc2sud2lwcHJvZDpmcy5maWxlOnZmLmZUVFpGRUJXVDhDSmM1LV84ZVhMM1E_dmVyc2lvbj0x" DWFX 2
      if (type === 'dw') {
        onURNCallback(selectedPartToDrag.value)
        // onURNCallback("dXJuOmFkc2sud2lwcHJvZDpmcy5maWxlOnZmLjVwb2R6T19LUTBDWXdqeEYtelVCaUE_dmVyc2lvbj0x")
      }
      else if (type === 'rvt') {
        const rvtURN = allURNsList.value[0].url
        onURNCallback(rvtURN)
      }
      else if (type === 'pdf') {
        onURNCallback("dXJuOmFkc2sud2lwcHJvZDpmcy5maWxlOnZmLjVsSGR2VWlZVFhtYUVfWUM3NXZkSEE_dmVyc2lvbj0x")
      }
      // $.get({
      //   url: embedURLfromA360
      //     .replace("public", "metadata")
      //     .replace("mode=embed", ""),
      //   dataType: "json",
      //   success: function (metadata) {
      //     if (onURNCallback) {
      //       let urn = btoa(metadata.success.body.urn)
      //         .replace("/", "_")
      //         .replace("=", "");
      //       onURNCallback(urn);
      //     }
      //   },
      // });
    }

    function getForgeToken(onTokenCallback) {
      $.post({
        url: myRevitFile
          .replace("public", "sign")
          .replace("mode=embed", "oauth2=true"),
        data: "{}",
        success: function (oauth) {
          if (onTokenCallback)
            onTokenCallback(oauth.accessToken, oauth.validitySeconds);
        },
      });
    }

    const onClickDragObj = (obj) => {
        console.log("OBJJJ", obj)
        selectedPartToDragId.value = obj.id
        selectedPartToDrag.value = obj.dwfxUrn
    }

//     const onDragStart = (event) => {

//       var instanceTree = viewer.model.getData().instanceTree;

// var allDbIdsStr = Object.keys(instanceTree.nodeAccess.dbIdToIndex);

// const allDBIDS = allDbIdsStr.map(function(id) { return parseInt(id)});

// console.log("BEFORE", allDBIDS)

//       event.dataTransfer.effectAllowed = 'copy';
//       // Hide the dragged image
//       var img = document.getElementById("blank");
//       event.dataTransfer.setDragImage(img, 0, 0);

//     }


    function onDragStart(event) {

var instanceTree = viewer.model.getData().instanceTree;

var allDbIdsStr = Object.keys(instanceTree.nodeAccess.dbIdToIndex);

const allDBIDS = allDbIdsStr.map(function(id) { return parseInt(id)});

console.log("BEFORE", allDBIDS)

// event.dataTransfer.effectAllowed = 'copy';
// // Hide the dragged image
// var img = document.getElementById("blank");
// event.dataTransfer.setDragImage(img, 0, 0);

}

    // Load car model
    const ModelState = {
      unloaded: 0,
      loading: 1,
      loaded: 2,
    };
    let modelState = ModelState.unloaded;

    function onDragOver(event) {
  event.preventDefault();
  switch (modelState) {
    case ModelState.unloaded: {
      modelState = ModelState.loading;
      getURN(myDwfxFile, "dw", function (urn) {
        let documentId = "urn:" + urn;

        Autodesk.Viewing.Document.load(documentId, (doc) => {
          let items = doc.getRoot().search(
            {
              type: "geometry",
              role: "3d",
            },
            true
          );
          if (items.length === 0) {
            console.error("Document contains no viewables.");
            return;
          }

          let tr = new THREE.Matrix4();
          viewer
            .loadDocumentNode(doc, items[0], {
              keepCurrentModels: true,
              placementTransform: tr,
            })
            .then(function (model2) {
              secondModel = model2;
              let bb = secondModel.getBoundingBox();
              extraZ = bb.max.z;
              modelState = ModelState.loaded;

              const position = {
                x: tr.elements[12],
                y: tr.elements[13],
                z: tr.elements[14],
              };
              console.log("Initial DWFx Position:", position);
            });
        });
      });
      break;
    }

    case ModelState.loaded: {
      let res = viewer.impl.hitTest(event.clientX, event.clientY, true);
      let pt = null;

      if (res) {
        // Use the intersect point if an object is hit
        pt = res.intersectPoint;
      } else {
        // Calculate ground plane intersection
        pt = viewer.impl.intersectGround(event.clientX, event.clientY);

        if (!pt) {
          // Default to a fallback position if ground plane isn't available
          pt = { x: 0, y: 0, z: 0 }; // Adjust default position as needed
          console.warn("Using default fallback position:", pt);
        }
      }

      if (pt) {
        let tr = secondModel.getPlacementTransform();
        tr.elements[12] = pt.x;
        tr.elements[13] = pt.y;
        tr.elements[14] = pt.z + extraZ; // Adjust height if necessary
        secondModel.setPlacementTransform(tr);

        console.log("Placed DWFx at:", {
          x: pt.x,
          y: pt.y,
          z: pt.z + extraZ,
        });

        viewer.impl.invalidate(true, true, true); // Refresh the viewer
      } else {
        console.error("Could not determine placement point.");
      }
      break;
    }
  }
}





    async function onDrop(event) {
      console.log("secondModel.getBoundingBox();", secondModel.getBoundingBox())
      const bBox = secondModel.getBoundingBox()

      const xCor = bBox.center().x + viewer.model.getData().globalOffset.x

const yCor = bBox.center().y + viewer.model.getData().globalOffset.y

const zCor = bBox.center().z + viewer.model.getData().globalOffset.z

console.log("onDrop FINAL CORDS CORRECT", xCor,yCor,zCor)

const finalDragObject = {
  x:xCor,
  y:yCor,
  z:zCor
}
draggedPositionToSend.value = finalDragObject
rotationToSend.value = {
  x:0,
  y:0,
  z:0
}




  // viewer.select(arrayOfDraggedObjects.value[0][0])


  var instanceTree = viewer.model.getData().instanceTree;

  var allDbIdsStr = Object.keys(instanceTree.nodeAccess.dbIdToIndex);

  const allDBIDS = allDbIdsStr.map(function(id) { return parseInt(id)});
  console.log("AFTER", allDBIDS)

      const screenX = parseInt(event.clientX);
      const screenY = parseInt(event.clientY);
      console.log("screenX", screenX)
      console.log("screenY", screenY)
      console.log("viewer.clientToWorld(screenX, screenY)", viewer.clientToWorld(screenX, screenY))
          
      let coords = viewer.impl.clientToViewport(screenX, screenY); //c.Vector3 {x: -0.9696521095484826, y: 0.9200779727095516, z: 1 (always 1)}
      let finalCoords = coords.unproject(viewer.impl.camera) //c.Vector3 {x: -26.379134321221724, y: 5.162777223710702, z: 1.3846547842336627}
      // draggedPositionToSend.value  = finalCoords

               // Step 2: Retrieve the model's unit and scale factor
   const unitFactor = await getModelUnitFactor();
   const scaleFactor = await getModelScaleFactor();

   console.log("unitFactor", unitFactor)
   console.log("scaleFactor", scaleFactor)

   const boundingBox = viewer.model.getBoundingBox();
    const center = boundingBox.center(); // Gets the center of the bounding box
    console.log("Origin point (Bounding Box Center):", center);

    // Get the model's transformation matrix
let transform = viewer.model.getPlacementTransform();

// Assuming the matrix is a 4x4 matrix
let rotationMatrix = transform.elements;

// Calculate rotation angle around Z-axis (in radians)
let rotationAngle = Math.atan2(rotationMatrix[1], rotationMatrix[0]);

// Convert from radians to degrees (if needed)
let rotationAngleInDegrees = rotationAngle * (180 / Math.PI);
console.log("Rotation Angle (in radians):", rotationAngle);
console.log("Rotation Angle (in degrees):", rotationAngleInDegrees);


      // Convert screen coordinates to Forge Viewer's world coordinates
    //   const result = viewer.clientToWorld(screenX, screenY);
    //   if (!result || !result.point) {
    //     console.error("Unable to convert to world coordinates.");
    //     return;
    //   }
    //   const dropPosition = result.point;  // This is a THREE.Vector3 of the drop position

    //   console.log("3D Drop Position:", dropPosition);


      event.preventDefault();
      modelState = ModelState.unloaded;
    }

    const options = {
      env: 'AutodeskProduction',
      accessToken: localStorage.getItem('access_token') // Replace with your actual access token
    };

    const startViewerModification = () => {
        Autodesk.Viewing.Initializer(options, function onInitialized() {
      var viewerDiv = document.getElementById("MyViewerDiv");
      viewer = new Autodesk.Viewing.GuiViewer3D(viewerDiv, { extensions: extensions });
      viewer.start();

      //viewer.addEventListener(Autodesk.Viewing.SELECTION_CHANGED_EVENT, onSelectionChangedNew);
      viewer.addEventListener(Autodesk.Viewing.AGGREGATE_SELECTION_CHANGED_EVENT, onGeometryChanged);

    //   viewerDiv.addEventListener('click', (event) => {
    //   const screenX = event.clientX;
    //   const screenY = event.clientY;

    //   // Perform a hit test to get the 3D coordinates of the clicked point
    //   const hitTestResult = viewer.impl.hitTest(screenX, screenY, true);
    //   if (hitTestResult) {
    //     const { x, y, z } = hitTestResult.intersectPoint;
    //     console.log("Clicked 3D coordinates:", { x, y, z });
    //   } else {
    //     console.log("No intersection found at this point.");
    //   }
    // });

      getURN(myRevitFile, 'rvt', function (urn1) {
        let documentId1 = "urn:" + urn1;

        // Load the first model (Revit model)
        Autodesk.Viewing.Document.load(documentId1, (doc1) => {
          let items1 = doc1.getRoot().search(
            {
              type: "geometry",
              role: "3d",
            },
            true
          );
          if (items1.length === 0) {
            console.error("Revit Document contains no viewables.");
            return;
          }

          viewer.loadDocumentNode(doc1, items1[0], {
            keepCurrentModels: true,
                    preserveView: true,  // 2D drawings
                    modelSpace: true,    // 2D drawings
                    applyRefPoint: true, // 3D shared coordinates
                    applyScaling: 'ft',   // force all models to same scale
//             globalOffset: {x: 
// -4.035603782499059,y:
// 4.446600915171814,z:
// 4.999999999998992},
            placementTransform: (() => {
              let matrix = new THREE.Matrix4();
              // Scale the 2D PDF sheet
              const modelScale = 0.3048;  // Scale down the 3D model to fit on the PDF
              // matrix.makeScale(modelScale, modelScale, modelScale);
              // matrix.setPosition(new THREE.Vector3(15, 25, 0)); // Place just above the PDF sheet

              return matrix;
            })()
          }).then(function (model1) {
            console.log("First model loaded (Revit)");
            mainModel = model1;
            viewer.fitToView();

            // Now, load the second model (e.g., another Revit, DWG, or PDF model)
            // getURN(myOtherModelFile, 'pdf', function (urn2) {
            //   let documentId2 = "urn:" + urn2;

            //   Autodesk.Viewing.Document.load(documentId2, (doc2) => {
            //     let items2 = doc2.getRoot().search(
            //       {
            //         type: "geometry",
            //         role: "2d", // Change to "3d" if it's another 3D model
            //       },
            //       true
            //     );
            //     if (items2.length === 0) {
            //       console.error("Second Document contains no viewables.");
            //       return;
            //     }

            //     viewer.loadDocumentNode(doc2, items2[0], {
            //       keepCurrentModels: true,
            //       preserveView: true,
            //       modelSpace: true,
            //       applyRefPoint: true,
            //       globalOffset: { x: 0, y: 0, z: 0 },
            //       placementTransform: (() => {
            //         let matrix = new THREE.Matrix4();
            //         // Scale the 2D PDF sheet
            //         const pdfScale = 70.0; // Increase this to make the PDF sheet larger
            //         matrix.makeScale(pdfScale, pdfScale, pdfScale);
            //         matrix.setPosition(new THREE.Vector3(-38, -50, -5)); // Keep it flat on the ground

            //         return matrix;
            //       })()
            //     }).then(function (model2) {
            //       addCustomPropertiesToModel(model2);
            //       console.log("Second model loaded (PDF or other)");
            //       secondModel = model2;




            //       // viewer.fitToView([mainModel.getRoot().getDefaultGeometry(), secondModel.getRoot().getDefaultGeometry()]);
            //       // Both models are now loaded. You can apply transformations or other operations here
            //     });
            //   });
            // });
          });
        });
      });



      function addCustomPropertiesToModel(model) {
        // Define your custom properties for the whole model
        model.customProperties = {
          'Project Name': 'Skyscraper Project',
          'Client Name': 'ABC Construction',
          'Completion Date': '2025-06-30',
          'Project Budget': '$50 million'
        };

        console.log('Custom properties added to model:', model.customProperties);
        console.log('Custom properties added to model:', model);
      }

      // Helper function to retrieve the unit factor (e.g., converting meters to feet if needed)

    });


    }

    async function getModelUnitFactor() {
   return viewer.model.getUnitString();
}
// Helper function to retrieve the scale factor
async function getModelScaleFactor() {

   return viewer.model.getUnitScale();
}

function getObjectRotation(viewer, dbId) {
    const model = viewer.model;
    const instanceTree = model.getData().instanceTree;
    const fragList = model.getFragmentList();

    let rotation = { x: 0, y: 0, z: 0 }; // To store the rotation angles

    instanceTree.enumNodeFragments(dbId, (fragId) => {
        const matrix = new THREE.Matrix4();
        fragList.getWorldMatrix(fragId, matrix); // Get the transformation matrix

        // Decompose the matrix into translation, rotation, and scale
        const pos = new THREE.Vector3();
        const quat = new THREE.Quaternion();
        const scale = new THREE.Vector3();
        matrix.decompose(pos, quat, scale);

        // Convert the quaternion to Euler angles (in radians)
        const euler = new THREE.Euler();
        euler.setFromQuaternion(quat, 'XYZ'); // Specify the rotation order

        // Convert radians to degrees
        rotation = {
            x: THREE.Math.radToDeg(euler.x),
            y: THREE.Math.radToDeg(euler.y),
            z: THREE.Math.radToDeg(euler.z),
        };
    });

    return rotation;
}


function onGeometryChanged (event) {
    console.log("onGeometryChanged")

    const dbId = event.selections[0].dbIdArray[0]
; // Get the selected object's dbId


const rotation = getObjectRotation(viewer, dbId);


var bBox = getModifiedWorldBoundingBox(
event.selections[0].fragIdsArray,
viewer.model.getFragmentList()
);

console.log("bBox", bBox.center())

const xCor = bBox.center().x + viewer.model.getData().globalOffset.x

const yCor = bBox.center().y + viewer.model.getData().globalOffset.y

const zCor = bBox.center().z + viewer.model.getData().globalOffset.z

console.log("FINAL CORDS CORRECT", xCor,yCor,zCor)
const finalDragObject = {
  x:xCor,
  y:yCor,
  z:zCor
}
draggedPositionToSend.value = finalDragObject
rotationToSend.value = rotation

console.log("Rotation (degrees) CORRECT:", rotation);

// var bBox2 = getOriginalWorldBoundingBox(
// event.selections[0].fragIdsArray,
// viewer.model.getFragmentList()
// );

// console.log("bBox2", bBox2.center())


}

// function onSelectionChangedNew (event) {
//   console.log("onSelectionChangedNew")

// }

function onSelectionChanged (event) {
    const dbId = event.dbIdArray[0]; // Get the selected object's dbId


    const rotation = getObjectRotation(viewer, dbId);
    console.log("Rotation (degrees):", rotation);

var bBox = getModifiedWorldBoundingBox(
  event.fragIdsArray,
  viewer.model.getFragmentList()
);

console.log("bBox", bBox.center())

var bBox2 = getOriginalWorldBoundingBox(
  event.fragIdsArray,
  viewer.model.getFragmentList()
);

console.log("bBox2", bBox2.center())

drawBox(bBox.min, bBox.max);
}

    //returns bounding box as it appears in the viewer
    // (transformations could be applied)
    function getModifiedWorldBoundingBox(fragIds, fragList) {

var fragbBox = new THREE.Box3();
var nodebBox = new THREE.Box3();

fragIds.forEach(function(fragId) {

    fragList.getWorldBounds(fragId, fragbBox);
    nodebBox.union(fragbBox);
});

return nodebBox;
}


function getOriginalWorldBoundingBox(fragIds, fragList) {

var fragBoundingBox = new THREE.Box3();
var nodeBoundingBox = new THREE.Box3();

var fragmentBoxes = fragList.boxes;

fragIds.forEach(function(fragId) {

    var boffset = fragId * 6;

    fragBoundingBox.min.x = fragmentBoxes[boffset];
    fragBoundingBox.min.y = fragmentBoxes[boffset+1];
    fragBoundingBox.min.z = fragmentBoxes[boffset+2];
    fragBoundingBox.max.x = fragmentBoxes[boffset+3];
    fragBoundingBox.max.y = fragmentBoxes[boffset+4];
    fragBoundingBox.max.z = fragmentBoxes[boffset+5];

    nodeBoundingBox.union(fragBoundingBox);
});

return nodeBoundingBox;
}
function drawLines(coordsArray, material) {

for (var i = 0; i < coordsArray.length; i+=2) {

    var start = coordsArray[i];
    var end = coordsArray[i+1];

    var geometry = new THREE.Geometry();

    geometry.vertices.push(new THREE.Vector3(
        start.x, start.y, start.z));

    geometry.vertices.push(new THREE.Vector3(
        end.x, end.y, end.z));

    geometry.computeLineDistances();

    var line = new THREE.Line(geometry, material);

    viewer.impl.scene.add(line);
}
}

function drawBox(min, max) {

var material = new THREE.LineBasicMaterial({
    color: 0xffff00,
    linewidth: 2
});

viewer.impl.matman().addMaterial(
    'ADN-Material-Line',
    material,
    true);

drawLines([

    {x: min.x, y: min.y, z: min.z},
    {x: max.x, y: min.y, z: min.z},

    {x: max.x, y: min.y, z: min.z},
    {x: max.x, y: min.y, z: max.z},

    {x: max.x, y: min.y, z: max.z},
    {x: min.x, y: min.y, z: max.z},

    {x: min.x, y: min.y, z: max.z},
    {x: min.x, y: min.y, z: min.z},

    {x: min.x, y: max.y, z: max.z},
    {x: max.x, y: max.y, z: max.z},

    {x: max.x, y: max.y, z: max.z},
    {x: max.x, y: max.y, z: min.z},

    {x: max.x, y: max.y, z: min.z},
    {x: min.x, y: max.y, z: min.z},

    {x: min.x, y: max.y, z: min.z},
    {x: min.x, y: max.y, z: max.z},

    {x: min.x, y: min.y, z: min.z},
    {x: min.x, y: max.y, z: min.z},

    {x: max.x, y: min.y, z: min.z},
    {x: max.x, y: max.y, z: min.z},

    {x: max.x, y: min.y, z: max.z},
    {x: max.x, y: max.y, z: max.z},

    {x: min.x, y: min.y, z: max.z},
    {x: min.x, y: max.y, z: max.z}],

    material);

viewer.impl.sceneUpdated(true);
}

// function onSelectionChanged(event) {
//     const selSet = viewer.getSelection();
// const targetElem = selSet[0];
// const model = viewer.model;
// const instanceTree = model.getData().instanceTree;
// const fragList = model.getFragmentList();
// let bounds = new THREE.Box3();
// instanceTree.enumNodeFragments( targetElem, ( fragId ) => {
//     let box = new THREE.Box3();
//     fragList.getWorldBounds( fragId, box );
//     bounds.union( box );
// }, true );
// const position = bounds.center();
// console.log("position", position)
//         }

        // -4.035603782499059, y: 4.446600915171814, z: 4.999999999998992 // OFFSET
        // -5.091594457626343, y: 6.4343485832214355, z: -5.080729246139526 // OBJECT

        // -4.035603782499059, y: 4.446600915171814, z: 4.999999999998992
        // 5.908405303955078, y: 5.4343485832214355, z: 2.75 

// function onSelectionChanged(event) {
//     //  const viewer = this.$refs.forgeViewer;
// // Reference to your Forge Viewer
//      const selection = viewer.getSelection();
//      if (selection.length > 0) {
//        const dbId = selection[0];
// // Get the first selected element's dbId

// // Get properties of the selected element
//        viewer.model.getProperties(dbId, (props) => {
//          if (props) {
//            const boundingBox = props.boundingBox;
//            const center = boundingBox.center();
// // Get the center of the bounding box

// // Get world coordinates
//            const worldCoords = viewer.model.getData().modelToWorld(center);

// // Get scaling factors (example values; replace these with actual scaling in your case)
//            const scaleX = viewer.getModel().getScale().x;
//            const scaleY = viewer.getModel().getScale().y;
//            const scaleZ = viewer.getModel().getScale().z;
//            console.log("Element Details: ", {
//              position: {
//                x: worldCoords.x,
//                y: worldCoords.y,
//                z: worldCoords.z
//              },
//              scale: {
//                x: scaleX,
//                y: scaleY,
//                z: scaleZ
//              }
//            });
//            return {
//              position: {
//                x: worldCoords.x,
//                y: worldCoords.y,
//                z: worldCoords.z
//              },
//              scale: {
//                x: scaleX,
//                y: scaleY,
//                z: scaleZ
//              }
//            };
//          }
//        });
//      } else {
//        console.error("No element selected in the viewer.");
//        return null;
//      }
//    }



// function onSelectionChanged(event) {
//    const selSet = viewer.getSelection();
//    const targetElem = selSet[0];
//    if (!targetElem) return; // Ensure there's a selected element
//    const model = viewer.model;
//    const instanceTree = model.getData().instanceTree;
//    const fragList = model.getFragmentList();
//    let bounds = new THREE.Box3();
//    // Get the global offset value
//    const globalOffset = model.getData().globalOffset;
//    // Enumerate through the fragments to calculate the bounding box in the world coordinates
//    instanceTree.enumNodeFragments(targetElem, (fragId) => {
//        let box = new THREE.Box3();
//        fragList.getWorldBounds(fragId, box);
//        bounds.union(box);
//    }, true);
//    // Calculate the center of the bounding box
//    let position = bounds.getCenter(new THREE.Vector3());
//    // Apply global offset to adjust to Revit's coordinate system
//    position.add(globalOffset);
//    // Convert to world coordinates if needed
//    const worldPosition = viewer.clientToWorld(position.x, position.y, position.z);
//    console.log("Revit-accurate position:", worldPosition);
// }


</script>

<style lang="scss" scoped>
      body {
            margin: 0;
        }
        #forgeViewer {
            width: 100%;
            height: 100%;
            margin: 0;
            background-color: #F0F8FF;
        }

        .search-container {
  position: relative;
  width: 500px;
}

.search-bar {
  display: flex;
  align-items: center;
}

input[type="text"] {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

button {
  margin-left: 10px;
  padding: 8px 12px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 4px;
}

button:hover {
  background-color: #0056b3;
}

.dropdown {
  position: absolute;
  width: 100%;
  background: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  max-height: 350px;
  overflow-y: auto;
  z-index: 1000;
  margin-top: 5px;
  padding: 10px;

  h4 {
    padding: 5px 10px;
  }
}

.dropdown ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.dropdown li {
  padding: 10px;
  cursor: pointer;
}

.dropdown li:hover {
  background-color: #f1f1f1;
}

/* Filter Modal */
.filter-modal {
  position: absolute;
  top: 44px;
  right: 0;
  /* transform: translate(-50%, -50%); */
  background: white;
  border: 1px solid #ccc;
  padding: 20px;
  z-index: 2000;
  width: 500px;
  max-height: 500px;
  overflow-y: auto;
}

.filter-content {
  display: flex;
  flex-direction: column;

  h6 {
    margin: 10px 0px;
  }
}

label {
  margin-bottom: 10px;
}

.bg-generic {
  .icon-filter {
    background: #e6e6e6;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    text-align: center;
    padding-top: 10px;
    color: #242424;
    margin-right: 10px;
    margin-left: 3px;
    display: inline-block;
  }
}

.overlay-loader-search {
  height: 100%;
  width: 100%;
  background: #fff;
  opacity: 0.5;
  position: absolute;
  z-index: 9;
}

#MyContainerDiv {
  width: 80%;
  height: 100%;
  // position: relative;
  float: left;
}
// #MyViewerDiv {
//   width: 80%;
//   height: 100%;
//   background-color: #f0f8ff;
// }
// #MyCar {
//   width: 10%;
//   margin: 5%;
//   position: relative;
//   float: left;
// }




// html, body {
//   height: 100%;
//   margin: 0;
//   overflow: hidden;
// }

// .container {
//   display: flex;
//   height: 100vh; /* Full viewport height */
//   margin: 0;
// }

// .filter-panel {
//   width: 300px; /* Adjust as needed */
//   overflow-y: auto; /* Allows scrolling if content is tall */
// }

// .viewer-panel {
//   flex: 1;
//   position: relative;
// }

// #MyViewerDiv {
//   position: absolute;
//   top: 0;
//   bottom: 0;
//   left: 0;
//   right: 0;
//   width: 100%;
//   height: 100%;
// }

#MyViewerDiv {
            width: 100%;
            height: 70vh;
            position: relative;
        }
        .controls {
            text-align: center;
            margin-top: 10px;
            padding: 10px 40px;
        }
</style>