<template>
  <div class="header-wrapper row m-0">
    <!-- <SearchBar /> -->
    <!-- <Logo /> -->

    <ChromeTabs />

    <!-- <div class="tabs">
        <div
          v-for="(tab, index) in tabs"
          :key="tab.id"
          :class="['tab', { active: activeTab === tab.id }]"
        >
          <span @click="selectTab(tab.id)">{{ tab.name }}</span>
          <button class="close-btn" @click="closeTab(tab.id)">✕</button>
        </div>
        <button class="new-tab-btn" @click="addTab">+ New Tab</button>
      </div>
      <div class="tab-content">
        <div v-if="activeTab">Content of {{ getTabName(activeTab) }}</div>
      </div> -->

    <div class="left-header col-xxl-5 col-xl-6 col-lg-5 col-md-4 col-sm-3 p-0">
      <!-- <div class="notification-slider"> -->
      <!-- <swiper :slidesPerView="1" :autoplay="{
        delay: 2500,
        disableOnInteraction: false,
      }" :modules="modules" class="notification-slider" direction="vertical">
        <swiper-slide class="h-100">
          <div class="d-flex h-100">
            <img src="@/assets/images/giftools.gif" alt="gif">
            <h6 class="mb-0 f-w-400">
              <span class="font-primary">Don't Miss Out! </span><span class="f-light">Out new update has been
                release.</span>
            </h6><i class="icon-arrow-top-right f-light"></i>
          </div>
        </swiper-slide>
        <swiper-slide class="h-100">
          <div class="d-flex h-100">
            <img src="@/assets/images/giftools.gif" alt="gif">
            <h6 class="mb-0 f-w-400">
              <span class="f-light">Something you love is now on sale! </span>
            </h6><a class="ms-1" href="https://1.envato.market/3GVzd" target="_blank">Buy now !</a>
          </div>
        </swiper-slide>
      </swiper> -->

      <!-- </div> -->
    </div>
    <div class="nav-right col-xxl-7 col-xl-6 col-md-7 col-8 pull-right right-header p-0 ms-auto">
      <ul class="nav-menus">
        <!-- <li>
          <span class="header-search"><svg @click="search_open()">
              <use href="@/assets/svg/icon-sprite.svg#search"></use>
            </svg>
          </span>
        </li>

        <li class="onhover-dropdown">
          <svg @click="bookmark_open()">
            <use href="@/assets/svg/icon-sprite.svg#star"></use>
          </svg>

          <Bookmark v-bind:activeclass="bookmark" />
        </li>
        <li>
          <Mode />
        </li>
        <Cart />
        <Notifications /> -->

        <!-- <Profile /> -->
      </ul>
    </div>
  </div>
</template>
<script>
import { ref } from "vue";
import { mapState } from 'vuex';
import Bookmark from '../bookmark';
import { Swiper, SwiperSlide } from "swiper/vue";
import { Autoplay } from "swiper";
import "swiper/css";
import Notifications from './notifications.vue';
import Mode from './mode';
import Cart from './cart';
import Profile from './profile';
import Logo from './logo.vue';
import SearchBar from './search';

import ChromeTabs from '../../pages/dashboard/filters/index.vue';

export default {
  //   name: 'Search',
  components: {
    Bookmark,
    Notifications,
    Mode,
    Cart,
    Profile,
    Logo,
    Swiper,
    SwiperSlide,
    SearchBar,
    ChromeTabs
  },
  data() {
    return {
      bookmark: false,
    };
  },
  computed: {
    ...mapState({
      menuItems: (state) => state.menu.searchData,
      megamenuItems: (state) => state.menu.megamenu,
      searchOpen: (state) => state.menu.searchOpen,
    }),
  },
  methods: {
    search_open() {
      this.$store.state.menu.searchOpen = true;
    },
    bookmark_open() {
      this.bookmark = !this.bookmark;
    },
  },
  setup() {

    const tabs = ref([
      { id: 1, name: "Tab 1" },
      { id: 2, name: "Tab 2" },
    ]);
    const activeTab = ref(tabs.value[0].id);
    const nextTabId = ref(3);

    const addTab = () => {
      tabs.value.push({ id: nextTabId.value, name: `Tab ${nextTabId.value}` });
      activeTab.value = nextTabId.value;
      nextTabId.value++;
    };

    const closeTab = (id) => {
      tabs.value = tabs.value.filter((tab) => tab.id !== id);
      if (activeTab.value === id && tabs.value.length > 0) {
        activeTab.value = tabs.value[0].id; // Switch to the first tab if the active one is closed
      } else if (tabs.value.length === 0) {
        activeTab.value = null; // No active tab if all tabs are closed
      }
    };

    const selectTab = (id) => {
      activeTab.value = id;
    };

    const getTabName = (id) => {
      const tab = tabs.value.find((tab) => tab.id === id);
      return tab ? tab.name : "Unknown Tab";
    };

    return {
      modules: [Autoplay],
      tabs,
      activeTab,
      addTab,
      closeTab,
      selectTab,
      getTabName
    };
  },
};
</script>

<style>
.tabs {
  display: flex;
  align-items: center;
  background-color: #f1f1f1;
  padding: 8px;
  border-bottom: 1px solid #ccc;
}

.tab {
  display: flex;
  align-items: center;
  padding: 8px 16px;
  margin-right: 4px;
  background-color: #e1e1e1;
  border-radius: 4px 4px 0 0;
  cursor: pointer;
}

.tab.active {
  background-color: white;
  border: 1px solid #ccc;
  border-bottom: none;
}

.tab span {
  margin-right: 8px;
}

.close-btn {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 14px;
}

.new-tab-btn {
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
  margin-left: 8px;
  padding: 8px 12px;
  color: #007bff;
}

.tab-content {
  padding: 16px;
  border: 1px solid #ccc;
  background-color: white;
  margin-top: -1px;
}
</style>
