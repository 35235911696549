<template>
  <div class="projects-container">
    <div v-if="isLoading" class="overlay-loader"></div>
    <div class="create-project">

      <div class="bg-generic mb-3 px-3 py-2 d-flex toolbar-upper overflow-auto-res">
        <i class="icon-arrow-left pe-3 clickable align-self-center"></i>
        <span class="mini-separator me-3"></span>
        <!-- <i class="icon-save clickable align-self-center pe-2 blue-color"></i>
        <span class=" align-self-center">Open</span>
        <span class="mini-separator mx-3"></span> -->
        <span @click="emitOpenTab('createProject', 'CreateProject')" class="align-self-center btn btn-primary">Create New Project</span>
        <!-- <span class="mini-separator mx-3"></span>
        <i class="icon-trash pe-2 clickable align-self-center red-color"></i>
        <span class=" align-self-center">Delete</span>
        <span class="mini-separator ms-3 me-3"></span>
        <span class=" align-self-center">Set Active</span>
        <span class="mini-separator ms-3 me-3"></span>
        <span class=" align-self-center">Set OnHold</span>
        <span class="mini-separator ms-3 me-3"></span>
        <span class=" align-self-center">Set Archive</span> -->
      </div>
      <!-- <div class="projects-sider">
     <div class="project-sider-items">
        Active
     </div>
     <div class="project-sider-items">
        On-Hold
     </div>
     <div class="project-sider-items">
        Archive
     </div>

     <div class="project-options">
      <div class="project-sider-items">
        Create New
     </div>
     <div class="project-sider-items">
        Open
     </div>
     <div class="project-sider-items">
       Delete
     </div>

     <div class="project-sider-items details">
       Details
     </div>

     <div class="project-sider-items">
       Set Active
     </div>

     <div class="project-sider-items">
       Set On-hold
     </div>

     <div class="project-sider-items">
       Set Archive
     </div>


     <div class="project-sider-items settings">
       Settings
     </div>
     </div>
    </div> -->

      <div class="projects-table">
          <ag-grid-vue :defaultColDef="defaultColDef" :rowSelection="'multiple'" :pagination="true" :paginationPageSize="10"
            :paginationPageSizeSelector="[10, 20, 30]" :rowData="projectData" :columnDefs="colDefs"
            style="height: 600px; width: 100%;" class="ag-theme-quartz">
          </ag-grid-vue>
        <!-- <a-table
        :row-selection="rowSelection"
        :columns="columns" :data-source="projectData">
        <template #projectStatus="{ text: projectStatus }">
          <span>
        <a-tag
        color="green"
        >
          {{projectStatus }}
        </a-tag>
      </span>
    </template>

    <template #actions= {record}>
          <span>
            <i @click="updateProject(record)" class="icon-pencil pe-2 clickable align-self-center font-16"></i>
            <i class="icon-trash pe-2 clickable align-self-center red-color font-16"></i>

      </span>
    </template>

        </a-table> -->
      </div>


    </div>

  </div>
</template>

<script setup>
import { ref, watch, inject, computed, onBeforeMount } from "vue"
import { getAccessToken } from "@/services/autodesk"
import { registerUser } from "@/services/auth"
import { getAllProjects } from "@/services/project"
import { defineEmits } from "vue";

import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import { AgGridVue } from "ag-grid-vue3"; // Vue Data Grid Component

import { eventBus } from '../../../eventbus';

import { useRouter } from 'vue-router';
const router = useRouter()



const defaultColDef = ref({
  filter: "agTextColumnFilter",
  floatingFilter: true,
})
// Row Data: The data to be displayed.
const rowData = ref([
  { make: "Tesla", model: "Model Y", price: 64950, electric: true },
  { make: "Ford", model: "F-Series", price: 33850, electric: false },
  { make: "Toyota", model: "Corolla", price: 29600, electric: false },
]);

const onClick = (params) => console.log("params", params);

// Column Definitions: Defines the columns to be displayed.
const colDefs = ref([
  { field: "name", checkboxSelection: true, flex: 10 },
  { field: "jobNumber" },
  {
    field: "projectStatus", headerName: 'Project Status', cellRenderer: params => {
      return `<div class="green-tag">${params.value}</div>`;
    }
  },
  { field: "projectType" },
  { field: "biddingBusiness" },
  { field: "activeBidding.name",  },
  {
    field: "actions", headerName: 'Action', cellRenderer: params => {
      // Create a unique ID for the button
      const buttonId = `btn-${params.node.id}`;
      // Create the button with the unique ID
      const button = `<i class="icon-pencil pe-2 clickable align-self-center font-16" id="${buttonId}"></i>`;
      // Use a timeout to ensure the button is in the DOM before adding the event listener
      setTimeout(() => {
        document.getElementById(buttonId)?.addEventListener('click', () => {
          updateProject(params.data);
        });
      }, 0);
      return button;
    }
  }
]);



const emitOpenTab = (type, name) => {
  eventBus.emit('open-tab', { type, name });
}


const columns = [
  {
    title: 'Project Name',
    dataIndex: 'name',
    key: 'name',
    width: '15%',
    sorter: (a, b) => a.name.localeCompare(b.name),
    sortDirections: ['descend', 'ascend'],

  },
  {
    title: 'Project Number',
    dataIndex: 'jobNumber',
    key: 'jobNumber',
    width: '12%',
  },
  {
    title: 'Project Status',
    dataIndex: 'projectStatus',
    key: 'projectStatus',
    width: '20%',
    slots: { customRender: 'projectStatus' },

  },
  {
    title: 'Project Type',
    dataIndex: 'projectType',
    key: 'projectType',
    width: '12%',
  },
  {
    title: 'Bidding Business',
    dataIndex: 'biddingBusiness',
    key: 'biddingBusiness',
    width: '20%',
  },
  {
    title: 'Bid Package',
    dataIndex: 'bidPackage',
    key: 'bidPackage',
    width: '20%',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    width: '10%',
  },
  {
    title: 'Actions',
    dataIndex: 'action',
    key: 'action',
    width: '20%',
    slots: { customRender: 'actions' },
  }
  // {
  //   title: 'Package Status',
  //   dataIndex: 'projectStatusId',
  //   key: 'projectStatusId',
  //   width: '18%',
  // },
  // {
  //   title: 'Bid Date',
  //   dataIndex: 'bidDate',
  //   key: 'bidDate',
  //   width: '9%',
  // },
  // {
  //   title: 'Price/Square Foot',
  //   dataIndex: 'priceSqFt',
  //   key: 'priceSqFt',
  //   width: '17%',
  // },
  // {
  //   title: 'Lead Estimator',
  //   dataIndex: 'leadEstimatorBiddingCompany_Fk',
  //   key: 'leadEstimatorBiddingCompany_Fk',
  //   width: '20%',
  // },
];
const projectData = ref([])
const isLoading = ref(false)
const data = [
  {
    key: 1,
    name: 'Job 1',
    jobNumber: 60,
    jobStatus: 'Under Construction',
    currentBid: 'Base Bid',
    packageStatus: 'Won',
    bidDate: '2-2-20024',
    price: '$12.33',
    priceSqFt: 'N/A',
    leadEstimate: 'Jack B',
    children: [
      {
        key: 11,
        name: 'Base Bid',
        jobNumber: 60,
        jobStatus: 'Under Construction',
        currentBid: 'Base Bid',
        packageStatus: 'Won',
        bidDate: '2-2-20024',
        price: '$12.33',
        priceSqFt: 'N/A',
        leadEstimate: 'Jack B',
      }
    ],
  },
];
const accessToken = ref("")
const emit = defineEmits(["select-project"]);

onBeforeMount(() => {
  // fetchAccessToken()
  handleRegisterUser()
  fetchAllProjects()
})

const updateProject = (record) => {
  // router.replace(`/project/create/${record.id}`);
  //router.push({ path: '/project/create', query: { id: record.id } })
  emit("select-project", record.id, record.name);
  console.log('id', record)
}

const rowSelection = () => {
  console.log('row')
}

const fetchAccessToken = () => {
  getAccessToken()
    .then(res => {
      localStorage.setItem('access_token', res.access_token)
      accessToken.value = res.access_token
      console.log('res', res)
    })
    .catch(err => {
      console.error(err)
    })
}



const handleNewProject = () => {
  router.replace('/project/create');
}

const handleRegisterUser = () => {
  registerUser()
    .then(res => {
      console.log('res', res)
    })
    .catch(err => {
      console.error(err)
    })
}

const fetchAllProjects = () => {
  isLoading.value = true
  getAllProjects()
    .then(res => {
      isLoading.value = false
      const allProjectsMapping = [...res].map((project, index) => {
        const { name, id, jobNumber, projectStatus, projectType, biddingCompany } = project
        return {
          ...project,
          name,
          id,
          jobNumber,
          projectStatus: projectStatus?.name,
          projectType: projectType?.name,
          biddingBusiness: biddingCompany?.name,
          bidPackage: '--',
          status: '--'
        }
      })
      projectData.value = allProjectsMapping
      console.log('res', res)
    })
    .catch(err => {
      isLoading.value = false
      console.error(err)
    })
}
</script>
<style lang="scss">
.projects-sider {
    position: fixed;
    width: 14%;
    background: rgb(240, 240, 240);
    height: 100vh;
    border-left: 1px solid #ccc;
    padding: 24px;
    margin-top: -16px;
    margin-left: -1px;
}

.project-sider-items {
  font-size: 16px;
  color: rgba(82, 82, 108, 0.8);
  text-align: left;
  padding: 10px 0;
  cursor: pointer;

  &.details {
    margin: 15px 0;
  }

  &.settings {
    position: absolute;
    bottom: 100px;
    padding-bottom: 10px;
  }
}

.project-options {
  margin-top: 100px;
}

.projects-table {
  // padding: 30px;
  width: 100%;
  // margin-left: 190px;
}

.projects-container {
  margin-left: -15px;
  display: flex;

  tr,
  td {
    font-size: 14px;
  }
}
.green-tag {
  background: #68a768;
    display: inline;
    color: #fff;
    padding: 3px 10px;
    border-radius: 4px;
}
</style>