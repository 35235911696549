// eventBus.js
import { reactive } from 'vue';

export const eventBus = reactive({
  events: [],
  emit(event, payload) {
    this.events.forEach((listener) => {
      if (listener.event === event) {
        listener.callback(payload);
      }
    });
  },
  on(event, callback) {
    this.events.push({ event, callback });
  },
  off(event, callback) {
    this.events = this.events.filter(
      (listener) => listener.event !== event || listener.callback !== callback
    );
  },
});
